import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { shipmentsActions } from '../../state/actions/shipments';
import fetchService from '../../fetchService';
import initSocketFactory from './initSocket';
import { ShipmentsBoard } from './ShipmentsBoard';
import { message } from 'antd';

class View extends Component {
    
    async componentDidMount(){
        const { setShipments, wsHost } = this.props;
        try {
            this.props.initSocket(`${wsHost}/shipments`);
            const shipments = await fetchService.getShipments();
            await setShipments(shipments);
        } catch (error) {
            message.error('Error');
        }
    }

    async componentWillUnmount(){
        const { ws } = this.props;
        ws.close();
    }

    render() {return (<ShipmentsBoard/>)}
};

View.propTypes = {
    setShipments: PropTypes.func.isRequired,
  };

  const mapState = ({shipments}) => {
      const { ws } = shipments;
      const { wsHost } = fetchService;
    return {
        wsHost,
        ws
    }
  };
  
const mapDispatch = (dispatch) => {
      return {
        initSocket: initSocketFactory(dispatch),
        setShipments: (list) => dispatch(shipmentsActions.setShipments(list)),
    }
};

export default connect(mapState, mapDispatch)(View);