import React from 'react';
import { Icon, message } from 'antd';
import { connect } from 'react-redux';
import { NewShipmentSteps } from '../../components';
import { OriginForm } from './OriginForm';
import { DestinationForm } from './DestinationForm';
import { DetailsForm } from './DetailsForm';
import { ConfirmationView } from './ConfirmationView';
import { newShipmentActions } from '../../state/actions/newShipment';
import { FormsHelper } from '../../helpers';
import fetchService from '../../fetchService';
import Constants from '../../helpers/constants';

let validator = null

const steps = [
  {
    title: 'Detalle',
    icon:(<Constants.IconFont type="icon-item_details" />),
    content: (<DetailsForm wrappedComponentRef={form => validator = form} />)
  },
  {
    title: 'Origen',
    icon:(<Constants.IconFont type="icon-truck" />),
    content: (<OriginForm wrappedComponentRef={form => validator = form} />)
  },
  {
    title: 'Destino',
    icon:(<Constants.IconFont type="icon-truckdelivery" />),
    content: (<DestinationForm wrappedComponentRef={form => validator = form} />)
  },
  {
    title: 'Resumen',
    icon:(<Icon type="check-circle" />),
    content: (<ConfirmationView/>)
  },

];

const mapStateToProps = ({newShipment}) => {
    return {
        currentStep: newShipment.step,
        steps,
        form: newShipment.step === 3 ? FormsHelper.getPostForm(newShipment): {},
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        nextStep: () => {
            validator.props.form.validateFields((err, values) => {
                if (!err) {
                    dispatch(newShipmentActions.nextStep());
                }
              });
        },
        prevStep: () => {
            dispatch(newShipmentActions.prevStep());
        },
        confirmShipment: async(form) => {
          try {
            const newShipment = await fetchService.createNewShipment(form);
            ownProps.finish(newShipment);
          } catch (error) {
            message.error('error');
          }
        },
    }
}

const NewShipment = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewShipmentSteps)

export default NewShipment;