import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Skeleton, Button, Statistic } from "antd";
import Constants from '../../../helpers/constants';
const { Countdown } = Statistic;

const waitAllocation = ({canPush, id }, push) => {
  const deadline = new Date(canPush).getTime() + 1000 * 60 * 60;
  const checkCanPush = deadline > Date.now() ? false : true;
  return(
    <div style={{ position: 'absolute', top: 20, right: 0, zIndex: 1, textAlign: 'end' }}>
      {
        checkCanPush
          ?
          <div className="align-center">
            <p className="margin-bottom-2 bold font-family-primary big-paragraph">¿No puedes esperar?</p>
            <Button type="primary" onClick={() => push(id)}>
              Presioname
            </Button>
          </div>
          :
          <div className="align-left" style={{marginLeft:'63%'}}>
            <Countdown  value={deadline}/>
            <p className="medium-paragraph">Hemos enviado notificaciones a todo humano en Sendengo para que ayuden a asignar tu carga.</p>
          </div>
      }
    </div>
  )
};

const AllocationInfoField = ({field}) => (
    <div>
      <span className="bold">{field.name}</span>
      {
        field.value
        ?
          <p className="semibold">{field.value}</p>
        :
          <Skeleton title={false} paragraph={{ rows: 1 }} />
      }
    </div>
);

const AllocationInfo = ({allocation, unitType}) => {

  const line = allocation ? allocation.trucking_company : null;
  const driver = allocation ? allocation.driver : null;
  const shipmentUnitType = allocation ? Constants.unitsTypes[unitType -1].text : null;
  const plates = allocation ? allocation.plates : null;
  return(
    <Col span={24}>
      <AllocationInfoField field={{ name: 'Linea:', value: line }}/>
      <AllocationInfoField field={{ name: 'Operador:', value: driver }}/>
      <AllocationInfoField field={{ name: 'Unidad:', value: shipmentUnitType }}/>
      <AllocationInfoField field={{ name: 'Placas:', value: plates }}/>
    </Col>
  )
};

const AllocationCard = (shipment, push, styles) => (
  <div className="margin-top-3">
    <p className="small-subtitle bold">Asignación</p>
    <Card style={styles}>
      <Row>
          <AllocationInfo {...shipment}/>
          {( !shipment.allocation && shipment.status === 1 ) && waitAllocation(shipment, push)}
      </Row>
    </Card>
  </div>
);

AllocationCard.propTypes = {
  shipment: PropTypes.object.isRequired,
  pushAllocation: PropTypes.func
};

export { AllocationCard };
