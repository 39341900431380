import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer,  Divider, Button } from 'antd';
import "./index.less";
import Constants from '../../../helpers/constants';

const setCoverageZone = (z) => (
  <div key={z.id}>
    <Divider key={z.id} orientation="left" className='divider-routes'>{z.name}</Divider>
    <ul>
      {
        z.coverage.map(c => <li key={`c-${c.id}`} className="list-style-none"><span  className="color-primary"><Constants.IconFont type="icon-mappin"/></span>{c.name}</li>)
      }
    </ul>
  </div>
)

class ZoneDrawer extends Component {

  render(){
    const { zonesList, visible, closeDrawer } = this.props;
    return(
        <Drawer
          title={<span className="normal-subtitle bold"><Constants.IconFont type="icon-Map" /> Zonas</span> }
          placement="right"
          closable={false}
          onClose={closeDrawer}
          visible={visible}
          >
            {
              zonesList.map(z => setCoverageZone(z))
            }
          <div id="footer-drawer">
            <Button type="primary" onClick={closeDrawer} style={{ marginRight: 8 }}>
              Cerrar
            </Button>
          </div>
        </Drawer>
    )
  }
}

ZoneDrawer.propTypes = {
  zonesList: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
}


export { ZoneDrawer };


