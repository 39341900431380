import React, { Component } from 'react';
import { Form, Input, Button, Alert, Icon, Spin, Divider } from 'antd';

import {  Link } from 'react-router-dom'

const LoginForm = Form.create({
    name: 'login-form',
    onFieldsChange(props, changedFields){ 

    },

})(
    class extends Component {
        handleSubmit = e =>{
            e.preventDefault();
            const { form, post } = this.props;
            form.validateFields((err, values) =>{
              if (!err) {
                post(values)
              }
            });
        };

        render(){
            const { getFieldDecorator } = this.props.form;
            const { status } = this.props;
            const errorList = error => Object.entries(error).map(([key, value]) => <p key={key}>{key}: {value}</p>);
            return(
                <Form layout='vertical' onSubmit={this.handleSubmit}>
                    <Form.Item hasFeedback label="Correo electrónico">
                        {
                            getFieldDecorator("email",{
                                rules:[
                                    {required: true, message: 'Ingresa tu correo electrónico'},
                                    {type: 'email', message: 'Ingresa un correo valido' }
                                
                                ]   
                            })(
                                <Input name="email" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}/>
                            )
                        }     
                    </Form.Item>
                    <Form.Item hasFeedback label="Contraseña">
                        {
                            getFieldDecorator("password", {
                                rules:[{required: true, message:'Ingresa tu contraseña'}]
                            })(
                                <Input name="password"   type="password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}/>
                            )
                        }
                        
                    </Form.Item>
                   
                    <div style={{ textAlign: 'center', marginBottom: 10 }}>
                        {
                            status.error &&
                            <Alert message={errorList(status.error)} type="error" showIcon style={{ padding: 0 }}/>
                        }
                        {
                            status.loading &&
                            <Spin />
                        }    
                    </div>
                    <Form.Item>
                        <Button htmlType="submit" block  size="large">INICIA SESIÓN</Button>
                    </Form.Item>
                   <Divider>ó</Divider>
                    <Form.Item>
                        <Button  block  size="large" type="primary">
                        <Link to="/register">
                            REGISTRATE
                        </Link>
                        
                        </Button>
                    </Form.Item>
                    {/*<Row type="flex" justify="end">
                        <a className="margin-bottom-3 medium-paragrap bold">
                            ¿Olvidaste tu contraseña?
                        </a>
                    </Row> */}
                </Form>
            )

        }
    }
)



export { LoginForm };