import { connect } from 'react-redux'
import { newShipmentActions } from '../../state/actions/newShipment'
import { ShipmentDetailsForm } from '../../components'

const mapStateToProps = state => {
    const { unitType } =state.newShipment.routeDetail;
    return {
        fields: state.newShipment.detailsFields,
        unitType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (changedFields) => dispatch(newShipmentActions.changeDetailsFields(changedFields)),
    }
}

const DetailsForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShipmentDetailsForm)

export { DetailsForm }