import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Col, Icon, Slider } from 'antd';
import Constants from '../../../helpers/constants';
import textHelper from '../../../helpers/texts';

const FormatPrice= new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
const marks = {
    0: {
        label: <span><Icon type={Constants.statusValues[0].icon} /> {Constants.statusValues[0].text}</span>,
      },
    1: {
        label: <span><Icon type={Constants.statusValues[1].icon} /> {Constants.statusValues[1].text}</span>,
      },
    2: {
        label: <span><Icon type={Constants.statusValues[2].icon} /> {Constants.statusValues[2].text}</span>,
    },
    3: {
        label: <span><Constants.IconFont type={Constants.statusValues[3].icon} /> {Constants.statusValues[3].text}</span>,
      }, 
    4: {
        label: <span><Constants.IconFont type={Constants.statusValues[4].icon} /> {Constants.statusValues[4].text}</span>,
    },
    5: {
        label: <span><Constants.IconFont type={Constants.statusValues[5].icon} /> {Constants.statusValues[5].text}</span>,
    },
    6: {
        label: <span><Icon type={Constants.statusValues[6].icon} /> {Constants.statusValues[6].text}</span>,
    },
};

const setQuoteAddress = quoteList => {
    const quote = quoteList[0].values;
    const { areaCode, municipality, road, settlement, extraReferences, place } = quote.address.values;
    return(
        <div>
            <p className="normal-paragraph bold margin-bottom-0">{ road }</p>
            <p className="normal-paragraph bold margin-bottom-0">{ settlement }</p>
            <p className="normal-paragraph bold margin-bottom-0">{`${municipality}, ${textHelper.getConstantState(quote.address.values)}`}</p>
            <p className="normal-paragraph bold ">{areaCode}</p>
            <p className="normal-paragraph margin-bottom-0 semibold"><Constants.IconFont type="icon-warehouse" /> {place}</p>
            <p className="normal-paragraph margin-bottom-0 semibold"><Constants.IconFont type="icon-contact1" /> {quote.contactName}</p>
            <p className="normal-paragraph margin-bottom-0 semibold"><Constants.IconFont type="icon-contact" /> {quote.contactPhone}</p>
            <p className="normal-paragraph semibold">{extraReferences}</p>      
        </div>
    ) 
};

const setShipmentInfo = ({pickup, delivery, totalRate, route, accessorials }) => {
    const rate = route.values.rate;
    const p = pickup[0].values;
    const pDate = new Date(p.datetime);
    const d = delivery[0].values;
    const dDate = new Date(d.datetime);
    return(
        <div>
            <p className="small-subtitle margin-bottom-0 bold"><Icon type="calendar" style={{ color: '#0CA669' }}/> Fecha de envio</p>
            <p className="margin-bottom-0">{pDate.toLocaleString('es-MX')}</p>
            <p className="margin-bottom-3">{p.extras}</p> 
            <p className="small-subtitle margin-bottom-0 bold"><Icon type="calendar" style={{ color: '#0CA669' }}/> Fecha de entrega</p>
            <p className="margin-bottom-0">{dDate.toLocaleString('es-MX')}</p>
            <p className="margin-bottom-3">{d.extras}</p> 
            <p className="small-subtitle margin-bottom-0 bold"><Constants.IconFont type="icon-money3" style={{ color: '#0CA669' }}/> Precio</p>
            <p className="margin-bottom-0 semibold">Tarifa de ruta: <span className="font-family-primary">{FormatPrice.format(rate)} MXN</span></p>
            {
                accessorials.map((a, i) => {
                    const accessorial = Object.entries(a)[0];
                    return(
                        <p key={i} className="margin-bottom-0 semibold">{`${accessorial[0]}: `}<span className="font-family-primary">{FormatPrice.format(accessorial[1])} MXN</span></p>
                    )
                })
            }
            <p className="margin-bottom-0 semibold">Total: <span className="font-family-primary">{FormatPrice.format(totalRate)} MXN</span></p>
        </div>
    ) 
}

const setShipmentDetail = ({ weight, description, route }) => {
    const unitType = route && textHelper.getUnitType(route.values);
    return(
        <div>
            <p className="margin-bottom-0">{description}</p> 
            <p className="margin-bottom-0">{`${weight} TONS`}</p>
            <p className="margin-bottom-0"><Constants.IconFont type="icon-truck" style={{color:"#0CA669"}}/> {unitType}</p>
        </div>
    )
}

const ShipmentDetailCard = ({shipment}) => (
    <Card>
        <Row className="margin-bottom-5">
            <Col sm={24} md={16} >
                <Row>
                    <Col sm={24} md={12} >
                        <p className="small-subtitle bold"><Icon type="environment" style={{ color: '#0CA669' }} /> Ubicacion de carga</p>
                        { shipment.pickup && setQuoteAddress(shipment.pickup) }
                    </Col>
                    <Col sm={24} md={12} >
                        <p className="small-subtitle bold"><Icon type="environment" style={{ color: '#0CA669' }} /> Enviar a </p>
                        { shipment.delivery && setQuoteAddress(shipment.delivery) }
                    </Col>
                </Row>
                <Row>
                    <p className="small-subtitle margin-bottom-0 bold margin-top-3"><Constants.IconFont type="icon-tl-warehouse-" style={{ color: '#0CA669' }}/> Detalles</p>
                    {setShipmentDetail(shipment)}
                </Row>
            </Col>
            <Col sm={24} md={8}  style={{borderLeft:"dashed 1px",paddingLeft: '16px'}}>
                { shipment.pickup && setShipmentInfo(shipment) }
            </Col>
        </Row>
        <Row type="flex" justify="center" className="margin-top-1">
            <Col span={20} className="margin-bottom-5">
                <Slider marks={marks} max={6} value={shipment.status} tipFormatter={null} style={{pointerEvents:'none' }} />
            </Col>      
        </Row>  
    </Card>)

ShipmentDetailCard.propTypes = {
    shipment: PropTypes.object.isRequired
}

export { ShipmentDetailCard }