import React, { Component } from 'react';
import { Row,Card } from 'antd';
import { connect } from 'react-redux';

import { loginActions } from "../state/actions/login";
import fetchService from "../fetchService";
import { LoginForm } from "../components";
import mySession from '../session';
import {  Link } from 'react-router-dom'

const logosvg = `${process.env.PUBLIC_URL}/statics/img/Final_Isotipo.svg`;


class View extends Component {

    // TODO move to container for Form
    async loginShipper(form) {
        const { postRequest, postSuccess, postError, history } = this.props;
        postRequest()
        try {
            const user = await fetchService.loginShipper(form);      
            await postSuccess(user);
            await mySession.saveSession(user);
            window.dataLayer.push({userID: user.id})
            history.push('/routes');
        } catch (error) {
            await postError(error);
        }
    }

    render(){
        const { status } = this.props;
        return(
            <Row type="flex" align="middle" justify="center" style={{height:'100vh', alignContent:'center'}} >
                <div style={{width:'100%'}}>
                    <Row type="flex" justify="center" align="middle">
                        <Link to="/" id="link-landing-login"><img className="margin-bottom-3" src={logosvg} alt="logo" id="link-image-login" onClick={this.props.onClickWelcome} style={{width:'100%', height:'76.1562px'}}/></Link>
                    </Row> 
                    <Row type="flex" justify="center" align="middle">
                      <Card style={{width:'410px'}}>
                          <Row type="flex" justify="center">
                              <h1 className="normal-title align-center bold">Iniciar sesión</h1>
                          </Row>
                          <LoginForm post={(form) => this.loginShipper(form)} status={status} />
                      </Card> 
                    </Row>      
                </div>
            </Row>     
        )

    }
}

const mapStateToProps = state => {
    const status = {
      loading: state.login.loginLoading,
      success: state.login.loginSuccess,
      error: state.login.loginError
    };
    return {
      status
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      postRequest: () => dispatch(loginActions.postLoginRequest()),
      postSuccess: f => dispatch(loginActions.postLoginSuccess(f)),
      postError: e => dispatch(loginActions.postLoginError(e))
    };
  };
  
  const LoginView = connect(
    mapStateToProps,
    mapDispatchToProps
  )(View);

export { LoginView };