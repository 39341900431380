import React from "react";
import {
  Row,
  Col,
  Input,
  AutoComplete,
  Form,
  Button,
  Select,
  Icon,
} from "antd";
import FormatPrice from '../../../helpers/currency';

const { Option, OptGroup } = AutoComplete;

export default Form.create({
  name: "search-form",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
})(
  class extends React.Component {
    handleSubmit = e => {
      e.preventDefault();
      const { form, searchData } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          searchData(values)
        }
      });
    };
    render() {
      const { zonesList, unitsTypes, searching, form, foundRoutes } = this.props;
      const { getFieldDecorator } = form;
      const options = zonesList.map(zone => (
        <OptGroup key={zone.id} label={zone.name}>
          {zone.coverage.map(opt => (
            <Option key={opt.id} value={opt.name}>
              {opt.name}
            </Option>
          ))}
        </OptGroup>
      ));
      const foundARoute = (foundRoutes && foundRoutes.length > 0) ? foundRoutes[0].values : null;
      return (
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Form.Item label="Tipo de unidad">
            {getFieldDecorator("unitType", {
              rules: [
                {
                  required: true,
                  message: "Selecciona una unidad"
                }
              ]
            })(
              <Select name="unitType">
                {unitsTypes.map(unitsType => (
                  <Select.Option key={unitsType.id} value={unitsType.id}>
                    {unitsType.text}
                  </Select.Option>
                ))}
                <Select.Option key='otra' value='otra'>
                  Otra
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Municipio de origen">
            {getFieldDecorator("origin", {
              rules: [
                {
                  required: true,
                  message: "Ingresa Origen"
                }
              ]
            })(
              <AutoComplete
                name="origin"
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={false}
                dropdownStyle={{
                  width: 300
                }}
                size="large"
                style={{
                  width: "100%"
                }}
                dataSource={options}
                placeholder="Cuauhtémoc"
                optionLabelProp="value"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toString()
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  allowClear={true}
                />
              </AutoComplete>
            )}
          </Form.Item>
          <Form.Item label="Municipio de destino">
            {getFieldDecorator("destination", {
              rules: [
                {
                  required: true,
                  message: "Ingresa destino"
                }
              ]
            })(
              <AutoComplete
                name="destination"
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={false}
                dropdownStyle={{
                  width: 300
                }}
                size="large"
                style={{
                  width: "100%"
                }}
                dataSource={options}
                placeholder="Toluca"
                optionLabelProp="value"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toString()
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input
                  allowClear={true}
                />
              </AutoComplete>
            )}
          </Form.Item>
          <Form.Item className="align-center">
            <Row gutter={8} type="flex" align="bottom">
              {
                (foundARoute && !searching)
                ?
                <div>
                  <Col span={12} style={{marginTop:'4px'}}>
                    <span className="color-primary  small-subtitle font-family-primary">{FormatPrice.format(foundARoute.rate)} MXN</span>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      style={{width:'170px'}}
                      id="Continuar"
                      href="/register"
                    >
                    CONTINUAR
                    <Icon type="right" />
                    </Button>
                  </Col>
                </div>
              :
              <div>
                <Col span={12}></Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={searching}
                    style={{width:'170px'}}
                    id="Buscar"
                  >
                    ELEGIR RUTA
                  </Button>
                </Col>
              </div>
              }
            </Row>
          </Form.Item>
        </Form>
      );
    }
  }
);
