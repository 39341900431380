import { Icon } from 'antd';
// STATUS VALUES 
const statusValues = [
    {
        id: 0,
        actionText: 'Por Pagar',
        text: 'Publicación',
        color: '#D6DDE3',
        icon: 'carry-out'
    },
    {
        id: 1,
        actionText: 'Por Asignar',
        text: 'Pago',
        color: '#33C15D',
        icon: 'dollar'
    },
    {
        id: 2,
        actionText: 'Por Cargar',
        text: 'Asignación',
        color: '#FEBD01',
        icon: 'user'
    },
    {
        id: 3,
        actionText: 'Por Salir a Ruta ',
        text: 'Carga',
        color: '#0CA669',
        icon: 'icon-trolleylogisticdeliverypackagesshipping'
    },
    {
        id: 4,
        actionText: 'Por Descargar',
        text: 'En ruta',
        color: '#2869E1',
        icon: 'icon-logistics'
    },
    {
        id: 5,
        actionText: 'Por Finalizar',
        text: 'Descarga',
        color: '#FFDC76',
        icon: 'icon-truck-loading'
    },
    {
        id: 6,
        actionText: 'Finalizados',
        text: 'Finalizado',
        color: '#FC3030',
        icon: 'check-circle'
    },
  
]

const statesValues = [
    {
        id: 1,
        name: 'Aguascalientes',
        shortName: 'AGS'
    },
    {
        id: 2,
        name: 'Baja California',
        shortName: 'BC'
    },
    {
        id: 3,
        name: 'Baja California Sur',
        shortName: 'BCS'
    },
    {
        id: 4,
        name: 'Campeche',
        shortName: 'CMP'
    },
    {
        id: 5,
        name: 'Coahuila',
        shortName: 'COA'
    },
    {
        id: 6,
        name: 'Colima',
        shortName: 'COL'
    },
    {
        id: 7,
        name: 'Chihuahua',
        shortName: 'CHI'
    },
    {
        id: 8,
        name: 'Chiapas',
        shortName: 'CHS'
    },
    {
        id: 9,
        name: 'Ciudad de México',
        shortName: 'CDMX'
    },
    {
        id: 10,
        name: 'Durango',
        shortName: 'DGO'
    },
    {
        id: 11,
        name: 'Guerrero',
        shortName: 'GRO'
    },
    {
        id: 12,
        name: 'Guanajuato',
        shortName: 'GTO'
    },
    {
        id: 13,
        name: 'Hidalgo',
        shortName: 'HGO'
    },
    {
        id: 14,
        name: 'Jalisco',
        shortName: 'JAL'
    },
    {
        id: 15,
        name: 'Estado de México',
        shortName: 'MEX'
    },
    {
        id: 16,
        name: 'Michoacan',
        shortName: 'MCH'
    },
    {
        id: 17,
        name: 'Morelos',
        shortName: 'MOR'
    },
    {
        id: 18,
        name: 'Nayarit',
        shortName: 'NAY'
    },
    {
        id: 19,
        name: 'Nuevo León',
        shortName: 'NL'
    },
    {
        id: 20,
        name: 'Oaxaca',
        shortName: 'OAX'
    },
    {
        id: 21,
        name: 'Puebla',
        shortName: 'PUE'
    },
    {
        id: 22,
        name: 'Queretaro',
        shortName: 'QRO'
    },
    {
        id: 23,
        name: 'Quintana Roo',
        shortName: 'QR'
    },
    {
        id: 24,
        name: 'Sinaloa',
        shortName: 'SIN'
    },
    {
        id: 25,
        name: 'San Luis Potosí',
        shortName: 'SLP'
    },
    {
        id: 26,
        name: 'Sonora',
        shortName: 'SON'
    },
    {
        id: 27,
        name: 'Tabasco',
        shortName: 'TAB'
    },
    {
        id: 28,
        name: 'Tlaxcala',
        shortName: 'TLX'
    },
    {
        id: 29,
        name: 'Tamaulipas',
        shortName: 'TMS'
    },
    {
        id: 30,
        name: 'Veracruz',
        shortName: 'VER'
    },
    {
        id: 31,
        name: 'Yucatán',
        shortName: 'YUC'
    },
    {
        id: 32,
        name: 'Zacatecas',
        shortName: 'ZAC'
    }
]

const unitsTypes = [
    {
        id: 1,
        short: '1.5 TONS',
        text: 'Camioneta 1.5 TONS',
        maneuverPrice: 500
    },
    {
        id: 2,
        short: '3.5 TONS',
        text: 'Camioneta 3.5 TONS',
        maneuverPrice: 800
    }
];
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1394427_zkw473q2b2p.js',
  });

const Constants = {
    statusValues,
    statesValues,
    unitsTypes,
    IconFont
}

export default Constants;