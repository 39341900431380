import { createAction } from 'redux-actions';

export const shipmentsTypes = {
    CONNECTING: 'SOCKET_CONNECTING',
    OPENED: 'SOCKET_OPENED',
    MESSAGED: 'SOCKET_MESSAGED',
    ERROR: 'SOCKET_ERROR',
    CLOSED: 'SOCKET_CLOSED',
    SET_SHIPMENTS_LIST: 'SET_SHIPMENTS_LIST',
    UPDATE_SHIPMENTS_LIST: 'UPDATE_SHIPMENTS_LIST',
}

/// shipments Actions
export const shipmentsActions = {
    // Sockets Actions
    connect: createAction(shipmentsTypes.CONNECTING),
    open: createAction(shipmentsTypes.OPENED),
    message: createAction(shipmentsTypes.MESSAGED),
    error: createAction(shipmentsTypes.ERROR),
    close: createAction(shipmentsTypes.CLOSED),

    setShipments: (shipments) => {
        return {
            type: shipmentsTypes.SET_SHIPMENTS_LIST,
            shipments
        }
    },

    updateShipment: (shipment) => {
        return {
            type: shipmentsTypes.UPDATE_SHIPMENTS_LIST,
            shipment
        }
    },

}