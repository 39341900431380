import React from 'react';

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper jzih1dpqqrg-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo jzjgnya1gmn-editor_css',
    children:
    `${process.env.PUBLIC_URL}/statics/img/Final_Logotipo.svg`,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: `/register`,
          children: [
            {
              children: (
                  <p style={{fontSize:'18px'}}>Registrarse</p>
              ),
              name: 'text',
              className: 'k1zfbhrvnoq-editor_css',
            },
          ],
        },

      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: "/login",
          children: [
            {
              children: (
                  <p>Iniciar Sesión</p>
              ),
              name: 'text',
              className: 'k1zfbtfn9j-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
