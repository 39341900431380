import { connect } from 'react-redux'
import { ShipmentConfirmationDetail } from '../../components';
import Constants from '../../helpers/constants';

const mapStateToProps = ({ newShipment}) => {
    const { unitType, rate, totalRate } = newShipment.routeDetail;
    const maneuverPrice = Constants.unitsTypes[unitType - 1].maneuverPrice;
    return {
        details: newShipment.detailsFields,
        origin: newShipment.originFields,
        destination: newShipment.destinationFields,
        maneuverPrice,
        rate,
        totalRate
    }
}

const ConfirmationView = connect(
    mapStateToProps
)(ShipmentConfirmationDetail)

export { ConfirmationView }