import React from "react";
import { MapData } from '../../../components'
import { Row } from 'antd'


const Banner = () =>(
    <section className="banner-landing" id="section-map">  
        <div  className="banner-landing-content"> 
            <Row className="align-center">
                <h2 className="normal-title bold"> Zona de Cobertura Actual</h2>
            </Row>  
            <div className="banner-landing-content-map" style={{position:"relative"}}>
                <MapData/>
            </div>   
        </div>
    </section>
)
export { Banner }