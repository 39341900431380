import { landingTypes } from '../actions/landing'
import helper from '../../helpers/performance'

const initialLanding = {
    routesList: [],
    zonesList: [],
    loading: true,
    searchFields: {},
    searching: false,
    foundRoutes: null,
}

const landing = (state = initialLanding, action) => {
    switch (action.type) {
        
        case landingTypes.SET_LANDING_ROUTES_LIST:
            return {
                ...state,
                routesList: action.routes,
                loading: false
            }

        case landingTypes.SET_LANDING_ZONES_LIST:
            return {
                ...state,
                zonesList: action.zones
            }

        case landingTypes.FILL_SEARCH_FIELDS:
            return {
                ...state,
                searchFields: {
                    ...state.searchFields,
                    ...action.changedFields
                }
            }

        case landingTypes.SET_SEARCH_FLAG:
            return {
                ...state,
                searching: action.flag
            }

        case landingTypes.SEARCH_ROUTES:
            return {
                ...state,
                foundRoutes: helper.searchRoutes(action.values, state),
            }

        default:
            return state
    }
}

export { landing }