import React, { Component } from 'react';
import { Divider,  List } from 'antd';
import PropTypes from 'prop-types';
import "./index.less";
import { Constants } from '../../../helpers';
import { ShipmentCard } from '../../molecules/ShipmentCard';


class MyShipmentsBoard extends Component {
    handleScrollRight = () =>{ 

    };
  
    render(){
        const { board } = this.props;
        const { statusValues } = Constants;
        const { publication, payment, assignment, load, onRoute, download, completed } = board;
        return(
            <div className="myShipmentsCards content-views">
                <h1 className="normal-title semibold">Mis embarques</h1>
                <div className="shipmentContainerMainCards">
                        {/* MOVE THIS TO OMPONENTS*/}
                        <div className="demo-infinite-container">
                            <Divider>{statusValues[0].actionText}</Divider>
                                <List
                                    className="list-items"
                                    itemLayout="horizontal"
                                    dataSource={publication}
                                    renderItem={item => <ShipmentCard shipment={item.values} /> }
                                />
                            <div className="shipment-footer"></div>
                        </div>
                        <div className="demo-infinite-container">
                            <Divider>{statusValues[1].actionText}</Divider>
                                 <List
                                    className="list-items"
                                    itemLayout="horizontal"
                                    dataSource={payment}
                                    renderItem={item => <ShipmentCard shipment={item.values}/> }
                                />
                                <div className="shipment-footer"></div>
                        </div>
                        <div className="demo-infinite-container">
                            <Divider>{statusValues[2].actionText}</Divider>
                                <List
                                    className="list-items"
                                    itemLayout="horizontal"
                                    dataSource={assignment}
                                    renderItem={item => <ShipmentCard shipment={item.values}/>}
                                />
                                <div className="shipment-footer"></div>
                        </div>
                        <div className="demo-infinite-container">
                            <Divider>{statusValues[3].actionText}</Divider>
                                <List
                                    className="list-items"
                                    itemLayout="horizontal"
                                    dataSource={load}
                                    renderItem={item => <ShipmentCard shipment={item.values}/>}
                                />
                                <div className="shipment-footer"></div>
                        </div>
                        <div className="demo-infinite-container">
                            <Divider>{statusValues[4].actionText}</Divider>
                                <List
                                    className="list-items"
                                    itemLayout="horizontal"
                                    dataSource={onRoute}
                                    renderItem={item => <ShipmentCard shipment={item.values}/>}
                                />
                                <div className="shipment-footer"></div>
                        </div>
                        <div className="demo-infinite-container">
                            <Divider>{statusValues[5].actionText}</Divider>
                                <List
                                    className="list-items"
                                    itemLayout="horizontal"
                                    dataSource={download}
                                    renderItem={item => <ShipmentCard shipment={item.values}/>}
                                />
                                <div className="shipment-footer"></div>
                        </div>
                        <div className="demo-infinite-container">
                            <Divider>{statusValues[6].actionText}</Divider>
                                <List
                                    className="list-items"
                                    itemLayout="horizontal"
                                    dataSource={completed}
                                    renderItem={item => <ShipmentCard shipment={item.values}/>}
                                />
                                <div className="shipment-footer"></div>
                        </div>
                    </div>
            </div>

        )
    }
}

MyShipmentsBoard.propTypes = {
    board: PropTypes.object.isRequired,
}

export { MyShipmentsBoard }