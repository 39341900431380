import { connect } from 'react-redux'
import { SiderMenu } from '../components'

const mapStateToProps = ({router}) => {
    const { pathname } = router.location;
    let menuKey = '0';
    switch (true) {
        case /routes/.test(pathname):
            menuKey = '1';
            break;

        case /shipments/.test(pathname):
            menuKey = '2';
            break;

        case /help/.test(pathname):
                menuKey = '3';
                break;
    
        default:
            break;
    }
    
    return {
        menuKey
    }
}

const Side = connect(
    mapStateToProps
)(SiderMenu)

export default Side;