import React, { Component } from 'react';
import { Row, Col, Card, Button } from 'antd';

class PaymentModalContent extends Component{
    render(){
        const  { goToHelp, paymentReference } = this.props;
        return(
            <div>
                <Row className="margin-bottom-2">
                    <p className="semibold margin-top-2">Es importante que la <span className="font-family-primary color-primary">REFERENCIA</span>  en tu pago sea la correcta, ya que no asignaremos tu carga hasta identificar tu pago.</p>
                </Row>
                <Row gutter={16} className="margin-bottom-5">
                    <Col md={12} sm={24}>
                        <p className="font-family-primary color-primary bold big-paragraph">TRANSFERENCIA</p>
                        <Card title={<span className="font-family-primary bold big-paragraph">Santander</span>} extra={<a href="https://www.santander.com.mx/demo/demo1.html" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/statics/img/santander.png`} alt="transferencia santander"/> </a>} >
                            <p className="font-family-primary bold big-paragraph">REFERENCIA: <span>{paymentReference}</span></p>
                            <p className="font-family-primary bold big-paragraph">N° de Cuenta: <span>92-00204454-3</span></p>
                            <p className="font-family-primary bold big-paragraph">CLABE: <span>014790920020445432</span></p>
                            <p className="font-family-primary bold big-paragraph">Destinatario: <span>GRUPO DE OPTIMIZACIÓN LOGÍSTICA GESR SA DE CV</span></p>
                        </Card>
                    </Col>
                    <Col md={12} sm={24}>
                    <p className="font-family-primary color-primary bold big-paragraph">DEPOSITO</p>
                        <Card title={<span className="font-family-primary bold big-paragraph">Santander</span>} extra={<a href="https://www.santander.com.mx/personas/santander-digital/faqs.html" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/statics/img/santander.png`} alt="deposito-santander"/></a>} >
                            <p className="font-family-primary bold big-paragraph">REFERENCIA: <span>{paymentReference}</span></p>
                            <p className="font-family-primary bold big-paragraph">N° de Cuenta: <span>92-00204454-3</span></p>
                            <p className="font-family-primary bold big-paragraph">CLABE: <span>014790920020445432</span></p>
                            <p className="font-family-primary bold big-paragraph">Destinatario: <span>GRUPO DE OPTIMIZACIÓN LOGÍSTICA GESR SA DE CV</span></p>
                        </Card>
                    </Col>
                </Row>
                <p className="semibold margin-top-2">Te devolveremos todo tu dinero si no logramos asignarte un transportista en el tiempo estimado. <Button type="link" style={{color:'#2869E1', padding:'0'}} onClick={goToHelp}>¿Quieres saber más?</Button></p>
                <p className="semibold margin-top-2  margin-bottom-5">¿Dudas? <Button type="link" style={{color:'#2869E1', padding:'0'}} onClick={goToHelp}>Contáctanos</Button></p>
                <hr  style={{borderColor:'#E9EDF0'}}></hr>
                <p className="semibold margin-top-2 color-secondary-300">Av. Paseo de la Reforma 23, Col. Tabacalera, C.P. 06030, CDMX.</p>
            </div>
        )
    }
}

export { PaymentModalContent }