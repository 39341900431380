import _ from 'lodash';

const getStatusGroup = list => {
    const status = ['publication', 'payment', 'assignment', 'load', 'onRoute', 'download', 'completed'];
    const group = _.groupBy(list, 'values.status');
    return _.mapKeys(group, (value, key)=> status[key]);
};

const getUnitGroup = list => {
    const units = ['1.5', '3.5'];
    const group = _.groupBy(list, 'values.unitType');
    return _.mapKeys(group, (value, key)=> units[key - 1]);
}

const findZoneByMunicipalityName = (zones, munipalityName) => _.find(zones, z => z.coverage.find(c => c.name === munipalityName));

const filterRoutes = ({values}, { unitType, originId, destinationId }) => {
    const unitFilter = values.unitType === unitType;
    const originFilter = values.pickup.find(z => z.values.id === originId)
    const destinationFilter = values.delivery.find(z => z.values.id === destinationId)
    const filter = unitFilter && originFilter && destinationFilter;
    return filter;
}

const searchRoutes = (values, state) => {
    const { unitType, origin, destination } = values;
    const  { routesList, zonesList } = state;
    const originZone = findZoneByMunicipalityName(zonesList, origin);
    const destinationZone = findZoneByMunicipalityName(zonesList, destination);
    if (originZone && destinationZone) {
        const foundRoute = _.filter(routesList, r => filterRoutes(r, { unitType, originId: originZone.id, destinationId: destinationZone.id }));
        return foundRoute;
    }
    return [];
}

export default {
    getStatusGroup,
    getUnitGroup,
    searchRoutes
}