import textHelper from "./texts";

const getValueIfExistsField = field => {
  const value = field ? field.value : null;
  return value;
};

const getImageIDValue = field => {
  const file = field.value[0];
  const value = file && file.response ? file.response.file_id : null;
  return value;
};

const mapValues = fields => {
  Object.keys(fields).forEach(
    key => (fields[key] = getValueIfExistsField(fields[key]))
  );
  return fields;
};

const mapFormFields = (fields, Form) => {
  Object.keys(fields).forEach(
    key =>
      (fields[key] = Form.createFormField({
        ...fields[key],
        value: getValueIfExistsField(fields[key])
      }))
  );
  return fields;
};

const getQuoteValues = fields => {
  let quote = {};
  Object.keys(fields).forEach(key => {
    const quoteKey = key.split("-")[1];
    quote[quoteKey] = textHelper.getStringValue(fields[key]);
  });
  quote["address"] = textHelper.getCompleteDirection(quote);
  return quote;
};

const getQuoteForm = fields => {
  const quote = {
    contact_name: "",
    contact_phone: "",
    extras: "",
    extra_references: "",
    accessorials: []
  };
  Object.keys(fields).forEach(key => {
    const quoteKey = key.split("-")[1];
    const pythonKey = convertToPythonKey(quoteKey);
    quote[pythonKey] = textHelper.getStringValue(fields[key]);
  });
  quote["address"] = {
    road: quote["road"],
    area_code: quote["area_code"],
    settlement: quote["settlement"],
    municipality: quote["municipality"],
    state: quote["state"],
    place: quote["place"],
    extra_references: quote["extra_references"]
  };
  return quote;
};

const convertToPythonKey = key => {
  const index = key.search(/[A-Z]+/);
  if (index > 0) {
    const pk = key.slice(0, index) + "_" + key.slice(index);
    return pk.toLocaleLowerCase();
  }
  return key;
};

const getPostForm = ({
  detailsFields,
  originFields,
  destinationFields,
  routeDetail
}) => {
  const { route, totalRate } = routeDetail;
  const { reference, description, weight } = detailsFields;
  const form = { route, total_rate: totalRate };
  if (reference && reference.value) {
    form.reference = reference.value;
  }
  form.pickup = [getQuoteForm(originFields)];
  form.delivery = [getQuoteForm(destinationFields)];
  form.description = textHelper.getStringValue(description);
  form.weight = textHelper.getStringValue(weight);
  return form;
};

const suggestRouteBodyRequest = ({ unitType, origin, destination, email }) => ({
  pickup: origin,
  delivery: destination,
  contact_email: email,
  unit_type: unitType === 'otra' ? 0 : unitType
});

export default {
  getImageIDValue,
  mapFormFields,
  mapValues,
  getQuoteValues,
  getPostForm,
  suggestRouteBodyRequest
};
