import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { landingActions } from '../../state/actions/landing';
import fetchService from '../../fetchService';
import { message, Alert, Row, Card, Col } from 'antd';
import SearchRoutes from './SearchRoutes';
import SuggestRoute from './SuggestRoute';

class Section extends Component {
  async componentDidMount(){
    const { loadData  } = this.props;
    await loadData()
  }
  render() {
    const { foundRoutes, searching } = this.props;
    const notFound = (foundRoutes && foundRoutes.length === 0) ? true : false;
    return (
      <Row type="flex" align="middle" justify="space-around" className="hero">
        <div style={{width:'100%', zIndex:2}} className="content-header">
          <Row type="flex" justify="center" align="middle" gutter={24}>
          <h1 className="normal-title color-white margin-top-3 d-none d-block-sm padding-left-2">Envía cargas de forma fácil, rápida y segura.</h1>
            <Col sm={24}  md={12} >
              <Card className="card-form-landing">
              {
                (notFound && !searching)
                ?
                  <div>
                    <Alert
                      description={<p className="align-left medium-paragraph">¡Lo sentimos! Actualmente no contamos con esta ruta.  ¿Quieres conocer nuestra <a href="#section-map"  className="bold">zona de cobertura </a> actual?</p>}
                      type="error"
                      
                      className="margin-bottom-2"
                    />
                    <p className="bold medium-paragraph margin-top-2">En cuanto tengamos Rutas disponibles te haremos llegar un correo</p>
                    <SuggestRoute />
                  </div>
                :
                  <div>
                    <SearchRoutes />
                  </div>
              }
              </Card>
            </Col>
            <Col sm={24}  md={12}>
              <h1 className="normal-title color-white margin-top-3 d-none-sm">Envía cargas de forma fácil, rápida y segura.</h1>
              <h2 className="small-title color-white d-none-sm">Contamos con transportistas altamente calificados para asegurar el traslado de tu mercancía.</h2>
              <h2 className="color-white small-title ">Elige entre las rutas disponibles dentro de nuestra zona de cobertura:</h2>
              <p className="color-white regular">- CDMX</p>
              <div className="text">
              <p  className="color-white regular margin-bottom-0">- Zona Metropolitana</p>
                <b>
                  <div className="innerText">
                  Poniente<br />
                  Oriente<br />
                  Norte<br />
                  </div>
                </b>   
              </div>
              {/*<p className="color-white regular" style={{clear:'left', fontSize:'18px'}}>- Queretaro<br />
                  - Puebla</p>  */}
            </Col>
          </Row>   
        </div>
      </Row>
    )
  }
}

Section.propTypes = {
  loadData: PropTypes.func.isRequired,
};

const mapState = ({landing}) => {
  const { searching, foundRoutes } = landing;
  return {
    foundRoutes,
    searching,
  }
};

const mapDispatch = (dispatch) => {
    return {
      loadData: async() => {
        try {     
          const routes = await fetchService.getAdminRoutes();
          const zones = await fetchService.getAdminZones();
          dispatch(landingActions.setRoutes(routes));
          dispatch(landingActions.setZones(zones));
        } catch (error) {
          message.error('Error al cargar datos');
        }
      },
  }
};

export default connect(mapState, mapDispatch)(Section);