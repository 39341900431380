export const loginTypes = {
    POST_REGISTER_REQUEST: 'POST_REGISTER_REQUEST',
    POST_REGISTER_SUCCESS: 'POST_REGISTER_SUCCESS',
    POST_REGISTER_ERROR: 'POST_REGISTER_ERROR',
    REGISTER_LOGIN: 'REGISTER_LOGIN',
    POST_LOGIN_REQUEST: 'POST_LOGIN_REQUEST',
    POST_LOGIN_SUCCESS: 'POST_LOGIN_SUCCESS',
    POST_LOGIN_ERROR: 'POST_LOGIN_ERROR',
}

/// login Actions
export const loginActions = {

    postRegisterRequest: () => {
        return {
            type: loginTypes.POST_REGISTER_REQUEST,
        }
    },

    postRegisterSuccess: (form) => {
        return {
            type: loginTypes.POST_REGISTER_SUCCESS,
            form
        }
    },

    postRegisterError: (error) => {
        return {
            type: loginTypes.POST_REGISTER_ERROR,
            error
        }
    },

    goToLogin: () => {
        return {
            type: loginTypes.REGISTER_LOGIN,
        }
    },

    postLoginRequest: () => {
        return {
            type: loginTypes.POST_LOGIN_REQUEST,
        }
    },

    postLoginSuccess: (form) => {
        return {
            type: loginTypes.POST_LOGIN_SUCCESS,
            form
        }
    },

    postLoginError: (error) => {
        return {
            type: loginTypes.POST_LOGIN_ERROR,
            error
        }
    },
}