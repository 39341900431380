import React from 'react'
import { Row, Col } from 'antd'

const Clients = () => (
    <section className="container-clients">
        <div className="content-clients">
        <Row gutter={16}>
            <Col span={24} className="align-center container-title">
                <h2 className="normal-title bold">Clientes que Confían en Nosotros</h2>
                <h3 className="normal-subtitle margin-top-2">Nos respalda la experiencia de grandes operaciones</h3>
            </Col>    
        </Row>
        <Row  gutter={16} type="flex"  align="middle"className="margin-bottom-5">
            <Col  md={4} xs={12}>
                <img src={`${process.env.PUBLIC_URL}/statics/img/absormex.jpg`} alt="absormex"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/clorox.jpg`} alt="clorox"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/escato.jpg`} alt="escato"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/estafeta.jpg`} alt="estafeta"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/KC.jpg`} alt="Kimberly-Clark"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/malta.jpg`} alt="maltaCleyton"/>
            </Col>
        </Row>
        <Row  gutter={16} type="flex" justify="space-around" align="middle"className="margin-bottom-5">
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/modelo.png`} alt="modelo"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/moderna.jpg`} alt="lamoderna"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/officemax.jpg`} alt="officemax"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/unilever.jpg`} alt="unilever"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/waldos.jpg`} alt="waldos"/>
            </Col>
            <Col  md={4} xs={12} >
                <img src={`${process.env.PUBLIC_URL}/statics/img/comex.jpg`} alt="comex"/>
            </Col>
        </Row> 
        </div>   
    </section>
) 
export {Clients}