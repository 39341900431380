import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, Divider } from 'antd';


const ShipmentDetailsForm = Form.create ({
    name: 'shipment-detail-form',
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { fields } = props;
        const descriptionValue = fields.description ? fields.description.value : null;
        const weightValue = fields.weight ? fields.weight.value : null; 
        const referenceValue = fields.reference ? fields.reference.value :null;
        return {
            reference: Form.createFormField({
                ...fields.reference,
                value: referenceValue
            }),
            description: Form.createFormField({
                ...fields.description,
                value: descriptionValue
            }),
            weight: Form.createFormField({
                ...fields.weight,
                value: weightValue,
            }),             
        };
    },
})(
    class extends Component{
        checkTruck15 = (rule, value, callback) => {
            const reg = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
            if ((!isNaN(value) && reg.test(value) && (parseFloat(value) <= 1.5) ) ) {
              callback();
            } 
            callback('Debe ser un digito');
          };
          checkTruck35 = (rule, value, callback) => {
            const reg = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
            if ((!isNaN(value) && reg.test(value) && (parseFloat(value) <= 3.5) ) ) {
              callback();
            } 
            callback('Debe ser un digito');
          };
        render(){
            const { getFieldDecorator } = this.props.form;
            const { TextArea } = Input;
            const { unitType } = this.props;
            return(
                <Row type="flex" justify="center" className="margin-top-2 padding-top-5">
                    <Col md={12} sm={24}>     
                            <Form layout='vertical'>
                                <Row  gutter={48}>
                                    <Col  span={24} className="gutter-row">
                                        <Divider orientation="left">Detalles</Divider>
                                        <Form.Item label="Referencia">
                                            {
                                                getFieldDecorator('reference')(<Input placeholder="SL11011"/>)
                                            }   
                                        </Form.Item>
                                        <Form.Item label="Descripción de la carga" hasFeedback>
                                            {
                                                getFieldDecorator("description",{
                                                    rules:[
                                                        {
                                                            required:true, message: 'Ingresa descripción de carga'
                                                        },
                                                    ]
                                                })(
                                                    <TextArea rows={3} maxLength="150"/>
                                                )
                                            }                                   
                                        </Form.Item>
                                        <Form.Item label="Peso" hasFeedback>
                                            {
                                                (unitType === 1)
                                                ?
                                                getFieldDecorator("weight",{
                                                  
                                                    rules:[
                                                        {
                                                            required:true, message: 'Ingresa el peso no mayor a 1.5 Tons',
                                                            validator: this.checkTruck15 
                                                        }
                                                    ]
                                                })  (
                                                      
                                                        <Input name="camioneta-15" addonAfter="Tons" placeholder="1.5" />
                                                     
                                                    )
                                                    :
                                                    getFieldDecorator("weight",{
                                                  
                                                        rules:[
                                                            {
                                                                required:true, message: 'Ingresa el peso no mayor a 3.5 Tons',
                                                                validator: this.checkTruck35 
                                                            }
                                                        ]
                                                    })  (
                                                          
                                                            <Input name="camioneta-15" addonAfter="Tons" placeholder="3.5" />
                                                         
                                                        )

                                            }           
                                        </Form.Item>            
                                    </Col>               
                                </Row>                    
                            </Form>
                    </Col>
                </Row>
            )
        }
    }
)

ShipmentDetailsForm.propTypes = {
    onChange: PropTypes.func.isRequired
}

export { ShipmentDetailsForm }