import { connect } from 'react-redux'
import { newShipmentActions } from '../../state/actions/newShipment'
import { ShipmentQuoteForm } from '../../components'
import Constants from '../../helpers/constants';
import fetchService from '../../fetchService';
import { message } from 'antd';

const mapStateToProps = ({newShipment}) => {
    const { unitType, destinationCoverage } = newShipment.routeDetail;
    const { destinationCPs, destinationSettlements } = newShipment;
    const maneuverPrice = Constants.unitsTypes[unitType - 1].maneuverPrice;
    return {
        type: 'destination',
        maneuverPrice,
        coverage: destinationCoverage,
        cpsList: Object.keys(destinationCPs),
        settlements: destinationSettlements,
        fields: newShipment.destinationFields,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (changedFields) => dispatch(newShipmentActions.changeDestinationFields(changedFields)),
        addTotal: (a) => dispatch(newShipmentActions.addTotal(a)),
        subtractTotal: (a) => dispatch(newShipmentActions.subtractTotal(a)),
        chooseMunicipality: async (m) => {
            try {
                const s = await fetchService.getSepomexByMunicipality(m);
                await dispatch(newShipmentActions.setDestinationCPs(s));
            } catch (error) {
                message.error('Error');
            }
        },
        chooseAreaCode: (ac) => dispatch(newShipmentActions.setDestinationSettlements(ac)),
    }
}

const DestinationForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShipmentQuoteForm)

export { DestinationForm }