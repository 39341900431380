import { loginTypes } from '../actions/login'

const initialLogin = {
}

const login = (state = initialLogin, action) => {
    switch (action.type) {
        
        case loginTypes.POST_REGISTER_REQUEST:
            return {
                ...state,
                registerLoading: true,
                registerError: null
            }

        case loginTypes.POST_REGISTER_SUCCESS:
            return {
                ...state,
                registerSuccess: true,
                registerLoading: false
            }

        case loginTypes.POST_REGISTER_ERROR:
            return {
                ...state,
                registerError: action.error,
                registerLoading: false
            }

        case loginTypes.REGISTER_LOGIN:
            return {
                ...state,
                registerError: null,
                registerLoading: null,
                registerSuccess: null,
            }

        case loginTypes.POST_LOGIN_REQUEST:
            return {
                ...state,
                loginLoading: true,
                loginError: null
            }

        case loginTypes.POST_LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccess: true,
                loginLoading: false
            }

        case loginTypes.POST_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.error,
                loginLoading: false
            }

        default:
            return state
    }
}

export { login }