import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { sessionReducer } from 'redux-react-session';
import { login } from './login';
import { newShipment } from './newShipment';
import { shipments } from './shipments';
import { shipmentDetail } from './shipmentDetail';
import { routes } from './routes';
import { landing } from './landing';

const shipperApp = history => combineReducers({
    router: connectRouter(history),
    session: sessionReducer,
    login,
    newShipment,
    shipments,
    shipmentDetail,
    routes,
    landing,
})

export default shipperApp