import React from 'react';
import { Card, Row,  Collapse, Icon, Col } from 'antd';
import  constantext from '../../helpers/infohelp';
import { AboutSendengo } from '../../components';
import Constants from '../../helpers/constants';

const { Panel } = Collapse;

const listItemsFaq = constantext.Faq.map((item) =>
<Panel header={<span className=" semibold small-subtitle">{item.title}</span>} key={item.id}>
  <p className="big-paragraph margin-bottom-2">{item.aparagraph}</p>
  <p className="big-paragraph margin-bottom-2">{item.bparagraph}</p>
  <p className="big-paragraph margin-bottom-2">{item.cparagraph}</p>
  <p className="big-paragraph margin-bottom-2">{item.dparagraph}</p>
</Panel>
);

const View = () => (
      <Row className="content-views">
          <h1 className="normal-title semibold">Ayuda</h1>
          <Card style={{marginTop:'16px'}}>
          <p className="small-title bold">FAQ</p>
          <Collapse defaultActiveKey={['0']} >
            {listItemsFaq}
          </Collapse>
          </Card>
          <Card style={{marginTop:'16px'}}>
            <p className="small-title bold">Video de soporte</p>
            <Row gutter={48}>
              <Col md={12} sm={24}>
                <iframe title="video-sendengo" style={{width:'100%', height:'250px'}} src="https://www.youtube.com/embed/WXDSxyaJExE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </Col>
              <Col md={12} sm={24}>
                <p className="align-center bold small-title">Rutas Sendengo</p>
                <p className="align-center big-paragraph semibold">Aprende como usar Rutas Sendengo, y manda todas tus cargas</p>
              </Col>
            </Row>
          </Card>
          <AboutSendengo/> 
          <Card style={{marginTop:'16px'}}>
          <p className="small-title bold">Contactanos</p>
          <p className="big-paragraph cmargin-bottom-2">¿Necesitas más ayuda? Llama a uno de nuestros asesores y ellos te ayudaran</p>
          <Row type="flex" justify="space-around">
            <Col md={8} sm={24} className="align-center">
              <Constants.IconFont type="icon-support" style={{fontSize:"50px"}}/>
              <p className="bold big-paragraph margin-top-2 margin-bottom-0">Frank</p>
              <a href="tel:5585297166" id="support-men-first" className="bold font-family-primary big-paragraph color-secondary"><Constants.IconFont type="icon-phone" style={{color:'#0CA669'}}/> 55 85 29 71 66</a>
            </Col>
            <Col md={8} sm={24} className="align-center">
            <Constants.IconFont type="icon-support1"  style={{fontSize:"50px"}} />
            <p className="bold big-paragraph margin-top-2 margin-bottom-0">Yau</p>
            <a href="tel:5564009382" id="support-woman" className="bold font-family-primary big-paragraph color-secondary"><Constants.IconFont type="icon-phone" style={{color:'#0CA669'}}/> 55 64 00 93 82</a>
            </Col>
            <Col md={8} sm={24} className="align-center">
            <Constants.IconFont type="icon-support"  style={{fontSize:"50px"}}/>
            <p className="bold big-paragraph margin-top-2 margin-bottom-0">José</p>
            <a href="tel:5572127404" id="support-men-second" className="bold font-family-primary big-paragraph color-secondary"> <Constants.IconFont type="icon-phone" style={{color:'#0CA669'}}/> 55 72 12 74 04</a>
            </Col>
          </Row>
          <p className="semibold big-paragraph margin-top-3">Otros asesores: </p>
          <p className="semibold big-paragraph margin-top-1 bold"><a className="font-family-primary color-secondary" id="support-Rea" href="tel:5525618169"><Constants.IconFont type="icon-phone" style={{color:"#0CA669"}}/> 55 25 61 81 69 </a></p>
          <p className="semibold big-paragraph bold margin-bottom-1 "><a className="font-family-primary color-secondary" id="support-Roa" href="tel:5549135654"><Constants.IconFont type="icon-phone" style={{color:"#0CA669"}}/> 55 49 13 56 54 </a></p>
          
          <p className="semibold big-paragraph">
          Teléfono de oficina:
          <a className="font-family-primary color-secondary bold" href="tel:5555108654"> <Icon type="phone" style={{color:"#0CA669"}}/> 55 55 10 86 54 </a>
          </p>
          </Card>
      </Row>
);

export default View;
