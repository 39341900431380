import { connect } from 'react-redux';
import { message } from 'antd';
import  { SuggestRouteForm }  from '../../components';
import { landingActions } from '../../state/actions/landing';
import { Constants, FormsHelper } from '../../helpers';
import fetchService from '../../fetchService';
import { sendSuccessNewRoute } from './components/Banners';

const mapState = ({landing}) => {
    const { loading } = landing;
    const { unitsTypes } = Constants;
    return {
        unitsTypes,
        loading,
        fields: landing.searchFields,
    }
}

const mapDispatch = dispatch => {
    return {
        onChange: (changedFields) => dispatch(landingActions.changeSearchFields(changedFields)),
        postSuggestedRoute: async(values) => {
            const form = FormsHelper.suggestRouteBodyRequest(values);
            try {
                await fetchService.suggestNewRoute(form);
                sendSuccessNewRoute();
            } catch (error) {
                message.error('Error al enviar datos');
            }
        }
    }
}

export default connect(mapState, mapDispatch)(SuggestRouteForm);