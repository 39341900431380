import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './state/reducers/configureStore';
import * as serviceWorker from './serviceWorker';
import LogRocket from 'logrocket';
import mySession from './session';

const store = configureStore()
sessionService.initSessionService(store, { validateSession: mySession.validateSession });

const LRAppID = process.env.REACT_APP_LOGROCKET_APP_ID ? process.env.REACT_APP_LOGROCKET_APP_ID : 'xaknbo/rutas-dev';
LogRocket.init(LRAppID);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */ }
            <App/>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
