import { connect } from 'react-redux'
import { VansCard } from '../../components';
import { routesActions } from '../../state/actions/routes';

const mapStateToProps = ({ routes }) => {
    const unitsTabs = Object.entries(routes.routesList);
    return {
        unitsTabs
    }
}

const mapDispatchToProps = dispatch => {  
    return {
        openDrawer: () => dispatch(routesActions.openDrawer()),
    }
}

const Units = connect(
    mapStateToProps,
    mapDispatchToProps
)(VansCard)

export { Units }