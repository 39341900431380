import React from "react";
import { Card, Collapse, Table, Row, Col } from "antd";
import {  Link } from 'react-router-dom';
import  './index.less';

const { Panel } = Collapse;
const columns = [
    {
      title: (<span className="bold">Accesorios</span>),
      className: 'accessories',
      dataIndex: 'accesorios',
      width: 280,
      render: text => <span className="color-primary">{text}</span>,
    },
    {
      title: <span className="bold">Acciones o Consecuencias</span>,
      dataIndex: 'acciones',
    },
    {
      title: <span className="bold">Costo</span>,
      dataIndex: 'costo',
    },
  ];
  const data = [
    {
      key: '1',
      accesorios: 'Estadía de carga',
      acciones: 'Si se llegara a generar una estadía de carga, el transportista tiene la indicación de no salir a ruta hasta que Sendengo no reciba confirmación de que esta ha sido correctamente liquidada.',
      costo: '$ 1,500.00 MXN después de 8 hrs cada 8 hrs',
    },
    {
      key: '2',
      accesorios: 'Estadía de descarga',
      acciones: 'Si se llegara a generar una estadía de descarga, será responsabilidad del embarcador (cliente directo de Sendengo) cubrir el monto estipulado antes de que transcurran 7 días naturales.',
      costo: '$ 1,500.00 MXN después de 8 hrs cada 8 hrs',
    },
    {
      key: '3',
      accesorios: 'Maniobra de carga (Camionetas 3.5)',
      acciones: 'Si seleccionaste "incluir maniobra de carga" nuestro transportista se encargará de la maniobra de carga. De lo contrario tu tendrás que encargarte de la maniobra de carga.',
      costo: '$800.00 MXN',
    },
    {
      key: '4',
      accesorios: 'Maniobra de carga (Camionetas 1.5)',
      acciones: 'Si seleccionaste "incluir maniobra de carga" nuestro transportista se encargará de la maniobra de carga. De lo contrario tu tendrás que encargarte de la maniobra de carga.',
      costo: '$500.00 MXN',
    },
    {
      key: '5',
      accesorios: 'Maniobra de descarga (Camionetas 3.5)',
      acciones: 'Nosotros le pagaremos al transportista $800.00 MXN al finalizar la descarga. Si tú cliente se encarga de la maniobra y desea cobrar un monto mayor al costo estandarizado por Sendengo, nuestro transportista NO ESTÁ OBLIGADO A PAGAR el excedente. Nuestro transportista solo pagará $800.00 MXN a tu cliente si marcaste la casilla de "maniobra de descarga".',
      costo: '$800.00 MXN',
    },
    {
      key: '6',
      accesorios: 'Maniobra de descarga (Camionetas 1.5)',
      acciones: 'Nosotros le pagaremos al transportista $500.00 MXN al finalizar la descarga. Si tú cliente se encarga de la maniobra y desea cobrar un monto mayor al costo estandarizado por Sendengo, nuestro transportista NO ESTÁ OBLIGADO A PAGAR el excedente. Nuestro transportista solo pagará $500.00 MXN a tu cliente si marcaste la casilla de "maniobra de descarga".',
      costo: '$500.00 MXN',
    },
    {
      key: '7',
      accesorios: 'Flete en Falso',
      acciones: 'Se cobrará la totalidad del flete, no hay devolución de dinero cuando sucede un flete en falso.',
      costo: '100% del costo flete.',
    },
    {
      key: '8',
      accesorios: 'Rechazo Total',
      acciones: 'Si se llegará a generar un rechazo total, tendrás que pagar un nuevo flete (y accesorios si lo amerita) para que la unidad regrese al punto de carga con tus artículos.',
      costo: 'El costo del flete será el mismo que se generó para el servicio original.',
    },
    {
      key: '9',
      accesorios: 'Rechazo parcial',
      acciones: 'Si necesitas que los artículos rechazados sean devueltos al origen inmediatamente tendrás que pagar un nuevo flete para que la unidad regrese al punto de carga.Los rechazos parciales para los cuales no se pague un flete de urgencia serán regresados a las oficinas de Sendengo. Tendrás hasta 3 días para recoger tu mercancía sin costo alguno. Si quieres que te enviemos tu mercancía, consolidaremos tus artículos en un flete al final del mes, por este servicio de almacenamiento y repartición te cobraremos $1000.00 MXN.',
      costo: 'El costo del flete será el mismo que se generó para el servicio original.1000 pesos por consolidar tu rechazo a fin de mes',
    },
  ];

const AboutSendengo = () =>(
    <Card  style={{marginTop:'16px'}}>
        <p className="small-title bold">Todo lo que necesitas saber de Rutas Sendengo</p>
        <Collapse defaultActiveKey={['1']} >
            <Panel header={<span className=" semibold small-subtitle">Sobre Rutas Sendengo </span>} key="1">
                <p className="big-paragraph margin-bottom-2">Rutas Sendengo es un esfuerzo por abrir la operación de Sendengo a clientes de pequeñas y medianas empresas.</p>
                <p className="big-paragraph margin-bottom-2">Sendengo ha creado, durante sus 4 años de operación, una comunidad de transporte profesional, confiable y certificado; fortalecida por trabajar mano a mano con clientes corporativos de talla internacional como Unilever, Grupo Modelo, Bacardí, La Moderna, entre muchos otros.</p>
                <p className="big-paragraph margin-bottom-2">Con el respaldo de esta comunidad es que Sendengo busca llevar una operación simple y confiable a toda empresa en México sin importar el tamaño.</p>
            </Panel>
            <Panel header={<span className=" semibold small-subtitle">Beneficios</span>} key="2">
                <p className="big-paragraph margin-bottom-2">En Rutas Sendengo podrás cotizar rápidamente gracias a las tarifas pre-negociadas con diversos transportistas que ya trabajan con nosotros.</p>
                <p className="big-paragraph margin-bottom-2">Constantemente estamos evaluando las tarifas en la plataforma. Hacemos esto para ofrecerte, semana tras semana, tarifas justas y competitivas. </p>
                <p className="big-paragraph margin-bottom-2">Una vez que encuentras la ruta que necesitas, no pierdes tu tiempo en negociar con nadie. La comisión de Sendengo está descontada dentro de la misma tarifa. Lo que ves es lo que pagas. </p> 
            </Panel>
            <Panel header={<span className=" semibold small-subtitle">Costos Opcionales Adicionales</span>} key="3" >
                <p className="big-paragraph  margin-bottom-2">Adicionalmente a la tarifa del flete puedes optar por seleccionar servicios adicionales los cuales representan un costo adicional. Sin embargo es totalmente tu decisión incluirlos o no.</p>
                <p className="big-paragraph  margin-bottom-2">A continuación tienes un listado de todos los costos accesoriales adicionales.</p>
                <Table columns={columns} dataSource={data}  size="small" bordered pagination={false}/>
            </Panel>
            <Panel header={<span className=" semibold small-subtitle">Nuestras Rutas</span>}  key="4" >
                <p className="big-paragraph margin-bottom-2">Rutas Sendengo es una aplicación viva que se expande gracias a tu participación. Las rutas que encuentras disponibles en Rutas Sendengo son las únicas disponibles en ese momento.</p>
                <p className="big-paragraph margin-bottom-2">¡Las rutas aumentarán con el tiempo hasta cubrir todo México! Pero necesitamos crecer ordenadamente. Queremos abrir primero las rutas más solicitadas. <Link to="/" className="bold color-action">Ver rutas disponibles.</Link></p>
                <p className="big-paragraph margin-bottom-2">Siempre que entrés a Rutas Sendengo y no encuentres la ruta que buscas envía un correo a <a className="bold color-action" href = "mailto: dnu@sendengo.com">dnu@sendengo.com </a> (asunto: solicitud de nueva ruta) con el origen y destino que estás buscando (de preferencia estado, municipio o código postal).</p>
                <p className="big-paragraph margin-bottom-2">Desde que te registraste eres parte de la comunidad Rutas Sendengo. Esta comunidad, por ahora pequeña, crece con tu ayuda. La primera vez que envíes un correo de “solicitud de nueva ruta” recibirás una recompensa sorpresa.</p>
                <p className="big-paragraph margin-bottom-2">Cada vez que ingreses a tu cuenta de Rutas Sendengo es posible que la ruta que necesitas ya esté incluida. Con cada correo que envíes solicitando dicha ruta aumenta su prioridad para nosotros.</p>
                <p className="big-paragraph margin-bottom-2 color-primary bold">¡Crezcamos juntos!</p>
            </Panel>
            <Panel header={<span className=" semibold small-subtitle">Tu Carga</span>}  key="5">
                <p className="big-paragraph margin-bottom-2">Hemos creado un proceso simple para que puedas publicar tu carga. Te recomendamos completar los detalles de tu carga con mucha claridad.</p>
                <p className="big-paragraph margin-bottom-2">Una vez realices tu pago nuestros algoritmos y asesores se pondrán a buscar, entre nuestros mejores transportistas, al primero disponible para realizar tu carga.</p>
                <p className="big-paragraph margin-bottom-2">Una vez encontremos al transportista le asignaremos tu carga. En ese momento el servicio empieza y ya no se podrá cancelar la carga.</p>
                <p className="big-paragraph margin-bottom-2">Antes de la asignación podrás cancelar la carga y recibir todo tu dinero de regreso, pero ten cuidado, somos muy rápidos para asignar cargas.</p>
                <p className="big-paragraph margin-bottom-2">Si no logramos asignarte un transportista en 24 horas puedes solicitarnos la devolución total de tu dinero.</p>
                <p className="big-paragraph margin-bottom-2">Para realizar tus pagos necesitarás un número de referencia que nosotros te proporcionaremos, único por carga. Te dejamos los datos bancarios de Sendengo que necesitarás para realizar tus pagos.</p>
                <Row>
                    <Col md={10} sm={24}>
                        <Card title={<span className="font-family-primary bold big-paragraph">Santander</span>} extra={<img src={`${process.env.PUBLIC_URL}/statics/img/santander.png`} alt="banco a depositar"/>} >
                            <p className="font-family-primary bold big-paragraph">REFERENCIA: <span>XXXXXX</span></p>
                            <p className="font-family-primary bold big-paragraph">N° de Cuenta: <span>92-00204454-3</span></p>
                            <p className="font-family-primary bold big-paragraph">CLABE: <span>014790920020445432</span></p>
                            <p className="font-family-primary bold big-paragraph">Destinatario: <span>GRUPO DE OPTIMIZACIÓN LOGÍSTICA GESR SA DE CV</span></p>
                        </Card> 
                    </Col>
                </Row>
                <p className="big-paragraph margin-bottom-2 margin-top-2">Tu carga va segura, tenemos un equipo de seguridad que verifica el estatus de todos los flete en tiempo real. Dentro de los detalles de tu carga, en tu perfil, podrás ver los avances en estatus de tu carga.</p>   
            </Panel>
        </Collapse>
    </Card>
)
export { AboutSendengo }