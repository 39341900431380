import { shipmentsActions } from '../../state/actions/shipments';
import { notification } from 'antd';
import { shipper } from "./../../fetchService/config";
const { ShipmentModel } = shipper.getLiftModels();

const dispatchOnMessage = (message, dispatch) => {
    const data = JSON.parse(message.data);
    switch (data.event) {
        case 'update':
            const { shipment, owner } = data;
            if (owner === shipper.user.email) {
                notification.open({
                    message: 'Tu carga se ha actualizado',
                    description: `# ${shipment.reference}`,
                  });
                const updatedShipment = new ShipmentModel(shipment);
                dispatch(shipmentsActions.updateShipment(updatedShipment));   
            }
            break;

        default:
            console.log('_');
            break;
    }
}

export default dispatch => (uri) => {
    if (!('WebSocket' in window)) {
        dispatch(shipmentsActions.error({ error: 'WebSocket is not supported by your browser' }));
        return;
    }

    const socket = new WebSocket(uri);
    dispatch(shipmentsActions.connect());
    
    socket.onopen = () => dispatch(shipmentsActions.open({ socket }));
    socket.onerror = () => dispatch(shipmentsActions.error({ error: true }));
    socket.onmessage = evt => dispatchOnMessage(evt, dispatch);
    socket.onclose = () => dispatch(shipmentsActions.close());
};

