import React, { Component } from 'react';
import { Row, Col, Card, Modal, Descriptions, message } from 'antd';
import {  Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { detailActions } from '../../state/actions/shipmentDetail';
import { Detail } from './ShipmentDetail';
import fetchService from '../../fetchService';
import initSocketFactory from './initSocket';
import { AllocationCard } from '../../components';
import  infohelp from '../../helpers/infohelp';
import './index.less';
import Constants from '../../helpers/constants'

const { warning, success } = Modal;
const FormatPrice = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

const showConfirm = allocation => {
  allocation && allocation.waybill_url
    ?
    success({
      title: 'Descarga tu Carta Porte',
      okText:  <span><Constants.IconFont type="icon-icon-icon_PortContractDowload"/> Descargar</span>,
      onOk: () => {
        window.location.href = allocation.waybill_url
      }
    })
    :
    warning({
      title: 'Esperando asignación.',
      content: 'Una vez que se te asigne tu operador podras descargar tu carta porte',
      icon: <Constants.IconFont type="icon-information1"  style={{color:"#2869E1"}}/>,
    })
};

const tablemaneuvering = infohelp.AccessorialPrices.map((item)=>
    <Descriptions.Item key={item.id}  label={<Link className="color-action" to="/help">{item.label}</Link>}>{item.description}</Descriptions.Item>
) 

class View extends Component {

  async componentDidMount(){
    const { match: { params }, fetchShipment, wsHost } = this.props;
    try {
      this.props.initSocket(`${wsHost}/shipments/${params.id}/`);
      fetchShipment(params.id);
    } catch (error) {
      message.error('Error');
    }
  }

  async componentWillUnmount(){
    const { ws } = this.props;
    ws.close();
  }

  render(){
      const { shipment, pushSendengo } = this.props;
      const activeStyle = { background: '#ABE0CB' };
      const paymentStyle = shipment.status === 0 ? {...activeStyle, minHeight:'258px' }: { minHeight:'258px'};
      const allocationStyle = shipment.status === 1 ? {...activeStyle, minHeight:'258px' }: { minHeight:'258px'};
      const waybillStyle = shipment.status === 2 ? {...activeStyle, minHeight:'208px' }: { minHeight:'208px'};
      const rememberStyle = shipment.status > 2 ? {...activeStyle, minHeight:'208px' }: { minHeight:'208px'};
      return(         
        <Row  className="content-views">
            <h1 className="normal-title semibold">Detalle de embarque #{shipment.reference}</h1>
            <Col span={24}>
              <Detail/>
              <div className="margin-top-2">     
                <Row  gutter={48}>
                    <Col md={12} sm={24}>
                        <p className="small-subtitle bold margin-top-3">Datos bancarios</p>
                        <Card style={paymentStyle}>
                            <p className="semibold" style={{fontSize:'19px'}}> Deposita via Spei <span className="bold font-family-primary"> {FormatPrice.format(shipment.totalRate)} MXN</span> a la:</p>
                            <p className="semibold color-secondary-400">Referencia:<span className="bold normal-paragraph color-secondary font-family-primary"> {shipment.paymentReference}</span></p>
                            <p className="semibold color-secondary-400">N° cuenta:<span className="bold normal-paragraph color-secondary font-family-primary"> 92-00204454-3</span></p>
                            <p className="semibold color-secondary-400">Cuenta Clabe:<span className="bold normal-paragraph color-secondary font-family-primary"> 014790920020445432</span></p>
                            <p className="semibold color-secondary-400">Banco:<span className="bold normal-paragraph color-secondary"> Santander</span></p>
                            <p className="semibold color-secondary-400">Beneficiario:<span className="bold normal-paragraph color-secondary"> GRUPO DE OPTIMIZACIÓN LOGÍSTICA GESR SA DE CV</span></p>
                        </Card>
                    </Col>   
                    <Col md={12} sm={24} >
                      {AllocationCard(shipment, pushSendengo, allocationStyle)}
                    </Col>
                     
                </Row>
                <Row  gutter={48}>
                    <Col md={12} sm={24} className="margin-top-3">
                        <p className="small-subtitle bold">Descarga Tú Carta Porte</p>
                        <Card style={waybillStyle} onClick={() => showConfirm(shipment.allocation)} hoverable className="card-download-cp">
                          <div id="div-download-cp" style={{minHeight:'208px'}}>
                            <div  className="container-icon-download"><Constants.IconFont type="icon-download" className="icon-download" /></div> 
                            <p id="p-download-cp"  className="align-center light"><Constants.IconFont type="icon-document" style={{fontSize:'90px',marginTop:"39px"}}/></p>
                          </div> 
                        </Card>
                    </Col> 
   
                    <Col md={12} sm={24}>
                        <p className="small-subtitle bold margin-top-3"><Constants.IconFont type="icon-information1"  style={{color:"#2869E1"}}/> Recuerda..</p>
                        <Card style={rememberStyle}>
                            <p className="semibold medium-paragraph"> Recibirás un correo de asignación con tu carta porte. Este documento trae toda la información de la carga y el transportista.</p>
                            <p className="semibold  medium-paragraph">Al finalizar el servicio, ambas partes deberán firmar la carta porte. Ambos deberán quedarse con una copia de la misma.</p>
                            <p className="semibold  medium-paragraph">Toma una fotografía legible del documento y envíala al correo <a className="bold color-action" href = "mailto: rutas.portes@sendengo.com">rutas.portes@sendengo.com </a>  lo antes posible</p>
                            <p className="semibold  medium-paragraph">Cualquier reclamo o aclaración que no venga indicado en la carta porte no podrá ser mediado por Sendengo.</p>
                        </Card>
                    </Col>
                </Row>
              </div>
              <Row className="margin-top-2">
              <Card>
              <Descriptions bordered size='small' column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                {tablemaneuvering}
              </Descriptions>
              </Card>
            </Row>  
            </Col>         
        </Row>
        
      )
  }
  
}

const mapState = ({ shipmentDetail }) => {
  const { details, ws } = shipmentDetail;
  const { wsHost } = fetchService;
  return {
      shipment: details.values ? details.values : {},
      wsHost,
      ws
  }
};

const mapDispatch = dispatch => {
  return {
      initSocket: initSocketFactory(dispatch),
      fetchShipment: async(id) => {
        const s = await fetchService.getShipmentDetail(id);
        dispatch(detailActions.setShipmentDetail(s))
      },
      pushSendengo: async(id) => {
        await fetchService.pushAllocation(id);
      }
  }
}

export default connect(mapState, mapDispatch)(View);