const Faq = [
    {
        id:1,
       title: '¿Qué es un accesorio?',
       aparagraph:'Dentro de la industria de transporte un “accesorio” hace referencia a cualquier costo adicional que se genere durante el servicio ajeno al servicio específico del transporte original (ejemplo: maniobra de carga, estadías, rechazo de producto, etc.).' ,
       bparagraph:'',
       cparagraph:'', 
       dparagraph:'',
    },
    {
        id:2,
        title: '¿Qué sucede si no logramos asignarte un transportista?',
        aparagraph:'Dado el caso que no logremos asignarte un transportista, a más tardar 6 horas antes de tu hora de carga indicada, te devolveremos todo tu dinero.' ,
        bparagraph:'Si cancelas tu carga antes que te asignemos un transportista, te devolveremos todo tu dinero. ',
        cparagraph:'Si cancelas tu carga después que te asignemos un transportista, se generará un “flete en falso”.', 
        dparagraph:'',
    },
    {
        id:3,
        title: '¿Qué es un flete en falso?',
        aparagraph:'Un flete en falso se genera cuando el viaje es cancelado después de que la unidad ya está en camino al punto de carga. ' ,
        bparagraph:'Se cobrará la totalidad del flete, no hay devolución de dinero cuando sucede un flete en falso.',
        cparagraph:'', 
        dparagraph:'',
    },
    {
        id:4,
        title: '¿Qués es un rechazo total?',
        aparagraph:'Un flete de rechazo total se genera cuando tu cliente final rechaza la totalidad de los artículos transportados. ' ,
        bparagraph:'Si se llegará a generar un rechazo total, tendrás que pagar un nuevo flete (y accesorios si lo amerita) para que la unidad regrese al punto de carga con tus artículos. El costo de ese flete será el mismo que se generó para el servicio original.',
        cparagraph:'', 
        dparagraph:'',
    },
    {
        id:5,
        title: '¿Qué es un rechazo parcial?',
        aparagraph:'Un flete de rechazo parcial se genera cuando una cantidad parcial de los artículos enviados es rechazada por tú cliente final.' ,
        bparagraph:'Si necesitas que los artículos rechazados sean devueltos al origen inmediatamente tendrás que pagar un nuevo flete (y accesorios si lo amerita) para que la unidad regrese al punto de carga. El costo del flete será el mismo que se generó para el servicio original.',
        cparagraph:'Los rechazos parciales para los cuales no se pague un flete de urgencia serán regresados a las oficinas de Sendengo. Tendrás hasta 3 días para recoger tu mercancía sin costo alguno. Si quieres que te enviemos tu mercancía, consolidaremos tus artículos en un flete al final del mes, por este servicio de almacenamiento y repartición te cobraremos $1000.00 MXN.', 
        dparagraph:'',
    },
    {
        id:6,
        title: '¿Qué es una estadía de carga?',
        aparagraph:'Dentro de la industria de transporte un “accesorio” hace referencia a cualquier costo adicional que se genere durante el servicio ajeno al servicio específico del transporte original (ejemplo: maniobra de carga, estadías, rechazo de producto, etc.).' ,
        bparagraph:'Una estadía de carga se genera cuando el proceso de cargar el camión demora más del tiempo estipulado en los términos del servicio (8 horas para cargas de Rutas Sendengo). El tiempo empieza a correr cuando la unidad se presenta a la ubicación indicada.',
        cparagraph:'Si se llegara a generar una estadía de carga, el transportista tiene la indicación de no salir a ruta hasta que Sendengo no reciba confirmación de que esta ha sido correctamente liquidada.', 
        dparagraph:'',
    },
    {
        id:7,
        title: '¿Qué es una estadía de descarga?',
        aparagraph:'Una estadía de descarga se genera cuando el proceso de descargar el camión demora más del tiempo estipulado en los términos del servicio (8 horas para cargas de Rutas Sendengo). El tiempo empieza a correr cuando la unidad se presenta a la ubicación indicada.' ,
        bparagraph:'Si se llegara a generar una estadía de descarga, será responsabilidad del embarcador (cliente directo de Sendengo) cubrir el monto estipulado antes de que transcurran 7 días naturales.',
        cparagraph:'', 
        dparagraph:'',
    },
    {
        id:8,
        title: '¿Qué es una maniobra de carga?',
        aparagraph:'Se denomina maniobra de carga al proceso de cargar, introducir y acomodar los artículos del cliente dentro de la unidad. ' ,
        bparagraph:'Si seleccionaste "incluir maniobra de carga" nuestro transportista se encargará de la maniobra de carga. De lo contrario tu tendrás que encargarte de la maniobra de carga.',
        cparagraph:'', 
        dparagraph:'',
    },
    {
        id:9,
        title: '¿Qué es una maniobra de descarga?',
        aparagraph:'Se denomina maniobra de descarga al proceso de retirar y acomodar los artículos del cliente fuera de la unidad.' ,
        bparagraph:'Si seleccionaste "incluir maniobra de descarga" nuestro transportista se encargará de la maniobra de carga o en su defecto se encargará de pagar el monto establecido a los maniobristas de tu cliente.',
        cparagraph:'Nuestro transportista solo pagará $XXX.XX MXN a tu cliente si marcaste la casilla de "maniobra de descarga".', 
        dparagraph:'Nosotros le pagaremos al transportista $XXX.XX MXN al finalizar la descarga. Si tú cliente se encarga de la maniobra y desea cobrar un monto mayor al costo estandarizado por Sendengo, nuestro transportista NO ESTÁ OBLIGADO A PAGAR el excedente.',
    }
];

const AccessorialPrices = [
    {
      id: 1,
      label:'Estadía de carga',
      description: '$1,500.00 MXN después de 8hrs cada 8hrs',   
    },
    {
        id: 2,
        label:'Estadía de descarga',
        description: '$1,500.00 MXN después de 8hrs cada 8hrs',   
    },
    {
        id: 3,
        label:'Maniobra de carga (Camionetas 3.5)',
        description: '$800.00 MXN',   
    },
    {
        id: 4,
        label:'Maniobra de carga (Camionetas 1.5)',
        description: '$500.00 MXN',   
    },
    {   id: 5,
        label:'Maniobra de descarga (Camionetas 3.5)',
        description: '$800.00 MXN',   
    },
    {
        id: 6,
        label:'Maniobra de descarga (Camionetas 1.5)',
        description: '$500.00 MXN',   
    },
    {
        id: 7,
        label:'Flete en Falso',
        description: '100% del costo flete.',   
    },
    {
        id: 8,
        label:'Rechazo Total',
        description: 'El costo del flete será el mismo que se generó para el servicio original.',   
    },
    {
        id: 9, 
        label:'Rechazo parcial',
        description: 'El costo del flete será el mismo que se generó para el servicio original. $1,000.00 MXN  por consolidar tu rechazo a fin de mes',   
    },
]

const constantext = {
    AccessorialPrices,
    Faq ,
}

export default constantext;