import React from 'react'
import mapboxgl from 'mapbox-gl'
import './index.less';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const infoZones = [
    {id: 1, nameZone:'Metropolitana Oriente', color:'#33C15D'},
    {id: 2, nameZone:'Metropolitana Centro', color:'#FC3030'},
    {id: 3, nameZone:'Metropolitana Norte', color:'#2869E1'},
    {id: 4, nameZone:'Metropolitano Poniente', color:'#FEBD01'},
]
var popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false
  });

class MapData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lng:-99.712, 
      lat: 19.402,
      zoom: 7.5,
    };
  }
  
  componentDidMount() {
    const { lng, lat, zoom } = this.state;
    const tilesetSource = process.env.REACT_APP_MAPBOX_TILESET_ID;
    const sourceLayer = process.env.REACT_APP_MAPBOX_SOURCE_LAYER;
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      trackResize: true,
      zoom,
    });

    map.on('move', () => {
      const { lng, lat } = map.getCenter();
      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: map.getZoom().toFixed(2)
      });

      
    });

    map.on('load', () => {
      map.resize();
      map.addSource("municipalities", {
        type: "vector",
        url: `mapbox://${tilesetSource}`
      });

    map.addLayer({
        "id": "zones",
        "type": "fill",
        "source": "municipalities",
        "source-layer":  `${sourceLayer}`,
        "paint": {
          "fill-outline-color": "rgba(225, 225, 225, 1)",
          "fill-opacity": 0.6,
          "fill-color": [
            "match",
            ['get', 'zone'],
            1,"#33C15D",
            2,"#FC3030",
            3,"#2869E1",
            4,"#FEBD01",
            "transparent",
          ], 
        },
 
      });
    });
    map.on('mousemove','zones', (e) => {
      const features = map.queryRenderedFeatures(e.point);
      const featuresZone =features[0].properties.zone;
      const nameMunicipalities = features[0].properties.name;
      if(featuresZone > 0 ){ 
        map.getCanvas().style.cursor = 'pointer'; 
          popup.setLngLat(e.lngLat)
          .setHTML(`${nameMunicipalities}`)
          .addTo(map); 
      }
     
      } );
    map.on('mouseleave', 'zones', function() {
        map.getCanvas().style.cursor = '';
        popup.remove();
      });
  }
  render() {
    const style = {
      position: 'absolute',
      height: '100%',
      width: '100%',
    }
    const renderLegendKeys = infoZones.map((info) => {
      return (
        <div key={info.id}>
          <span className='legend-key' style={{ backgroundColor: info.color }} />
          <span>{`${info.nameZone}`}</span>
        </div>
      )
    });
   
    return (
      <div>
        <div ref={el => this.mapContainer = el} style={style}/>
        <div className='map-overlay' id='legend'>
        <h2 className="font-family-primary margin-bottom-1">Zonas Sendengo</h2>
            {renderLegendKeys}
        </div>     
      </div>
    );
  }
}

export { MapData }
