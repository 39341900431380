import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input, Select, Divider, DatePicker, TimePicker, Switch, Tooltip, Icon } from 'antd';
import { FormsHelper } from '../../../helpers';
import { Constants } from '../../../helpers';
import 'moment/locale/es';
import FormItem from 'antd/lib/form/FormItem';
import './index.less'

const { TextArea } = Input;
const { Option } = Select;
const format = 'HH:mm';
const formatprice= new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

const  disabledDate = date => {
    let DateEnd = new Date();
    DateEnd.setDate(DateEnd.getDate() + 15);
    let DateStart= new Date();
    DateStart.setDate(DateStart.getDate() )
    if(date > DateEnd || date < DateStart ){
        return date
    }
};

const countAccesorials = (props, changedFields) => {
    const field = changedFields[Object.keys(changedFields)[0]];
    const fieldName = field && field.name.split('-')[1];
    const fieldType = field && field.name.split('-')[0];
    const maneuverType = fieldType === 'destination' ? 'descarga' : 'carga';
    if (fieldName === 'maneuver'){
        const { maneuverPrice, addTotal, subtractTotal } = props;
        if (field.value) {
            formatprice.format(addTotal(maneuverPrice));
            changedFields[`${fieldType}-accessorials`] = { value: [{ [`Maniobra de ${maneuverType}`] : maneuverPrice }] }
        } else {
            formatprice.format(subtractTotal(maneuverPrice));
            changedFields[`${fieldType}-accessorials`] = { value: [] }
        }
    }
    return changedFields;
};

const changeMunicipality = ({ coverage, chooseMunicipality }, changedFields) => {
    const field = changedFields[Object.keys(changedFields)[0]];
    if (field && field.value) {
        const fieldName = field && field.name.split('-')[1];
        if (fieldName === 'municipality') {
            const idField = field && field.name.split('-')[0];
            const m = coverage.find(s => s.name === field.value);
            chooseMunicipality(m.id);
            changedFields[`${idField}-state`] = { value: m.state };
            changedFields[`${idField}-areaCode`] = { value: null };
            changedFields[`${idField}-settlement`] = { value: null };
        }
    }
}

const changeAreaCode = (props, changedFields) => {
    const field = changedFields[Object.keys(changedFields)[0]];
    const fieldName = field && field.name.split('-')[1];
    if (fieldName === 'areaCode'){
        const { chooseAreaCode } = props;
        chooseAreaCode(field.value);
    }
};

const ShipmentQuoteForm = Form.create ({
    name: 'quote-form',
    onFieldsChange(props, changedFields) {
        countAccesorials(props, changedFields);
        changeMunicipality(props, changedFields);
        changeAreaCode(props, changedFields);
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        const { fields } = props;
        const formFields = FormsHelper.mapFormFields(fields, Form);
        return formFields;
    },
})(
    class extends Component{
        render(){
            const { getFieldDecorator } = this.props.form;
            const { type, maneuverPrice, coverage, cpsList, settlements } = this.props;
            const label = (type === "origin") ? "carga": "descarga"
            const tooltipText = (type === "origin") ? "Se denomina maniobra de carga al proceso de cargar, introducir y acomodar los artículos del cliente dentro de la unidad.": " Se denomina maniobra de descarga al proceso de retirar y acomodar los artículos del cliente fuera de la unidad."
            return(
                    <Row type='flex' justify='center' className='margin-top-2'>
                        <Col span={24}>
                            <Form layout='vertical'>
                                <Row gutter={16}>
                                    <Col md={8} sm={24}>
                                        <Divider orientation='left'>Dirección</Divider>
                                        <Form.Item label='Municipio / Localidad' hasFeedback>
                                            {
                                                getFieldDecorator(`${type}-municipality`, {
                                                    rules: [
                                                        { required:true, message: 'Ingresa tu municipio' }                                                                
                                                    ]
                                                })(
                                                    <Select>
                                                        {
                                                            coverage.map(m => <Option key={m.id} value={m.name}>{m.name}</Option>)
                                                        }
                                                    </Select>)
                                            }                                                   
                                        </Form.Item>                                          
                                        <Row gutter={16}>
                                            <Col  md={10} sm={24}>
                                                <Form.Item hasFeedback label='Codigo postal'>
                                                    {
                                                        getFieldDecorator(`${type}-areaCode`,{
                                                            rules:[
                                                                {
                                                                    required:true, message: 'Ingresa tu codigo postal'
                                                                }
                                                            ]
                                                        })(
                                                            <Select showSearch placeholder='56600'>
                                                                {
                                                                    cpsList.map(cp => <Option key={cp} value={cp}>{cp}</Option>)
                                                                }
                                                            </Select>
                                                        )
                                                    }       
                                                </Form.Item>
                                            </Col>
                                            <Col  md={14} sm={24}>
                                                <Form.Item label='Estado' hasFeedback>
                                                    {
                                                        getFieldDecorator(`${type}-state`,{
                                                            rules:[
                                                                { required:true, message:'Ingresa tu estado' }
                                                            ]
                                                        })(
                                                        <Select disabled>
                                                            {
                                                                Constants.statesValues.map(s => <Option key={s.id} value={s.id}>{s.name}</Option>)
                                                            }
                                                        </Select>)
                                                    }
                                                    
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item hasFeedback label='Colonia'>
                                        {
                                            getFieldDecorator(`${type}-settlement`, {
                                                rules:[
                                                    {
                                                        required: true, message: 'Selecciona tu colonia'
                                                    }
                                                    ]                               
                                                })(
                                                    <Select>
                                                        {
                                                            settlements.map(s => <Option key={s.id} value={s.settlement}>{s.settlement}</Option>)
                                                        }
                                                    </Select>
                                                    )
                                        }                                                        
                                        </Form.Item>            
                                        <Form.Item label='Calle y Número' hasFeedback>
                                            {
                                                getFieldDecorator(`${type}-road`, {
                                                    rules: [
                                                        {
                                                            required:true, message:'Ingresa tu calle y número'
                                                        }
                                                    ]
                                                })(
                                                        <Input placeholder='Reforma 23' />
                                                    )
                                            }            
                                        </Form.Item>
                                    </Col>
                                    <Col md={8} sm={24} >
                                        <Divider orientation='left'>Información General</Divider>
                                        <Row gutter={16}>
                                            <Col span={12} >
                                                <Form.Item label='Fecha' hasFeedback>
                                                    {
                                                        getFieldDecorator(`${type}-datetime`,{
                                                        rules:[
                                                            {
                                                                 required:true, message: 'Ingresa una fecha'
                                                            }
                                                            ]
                                                        })(
                                                            <DatePicker onChange={this.onChange} placeholder='12/09/2019' disabledDate={disabledDate} showToday={false}  renderExtraFooter={() => <p className="big-paragraph"><span className="color-error">**</span>12 horas para asignar</p>}/> 
                                                            )
                                                    }
                                                </Form.Item>
                                            </Col>
                                            <Col  span={12} >
                                                <Form.Item label='Hora' hasFeedback>
                                                    {
                                                        getFieldDecorator(`${type}-datetime`,{
                                                        rules:[
                                                            {
                                                                required:true, message:'Ingresa tu hora'
                                                            }
                                                            ]
                                                        })(
                                                            <TimePicker onChange={this.onChange} format={format} placeholder='12:30' minuteStep={30}/>  
                                                            )
                                                        }
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item label='Nombre del lugar'>
                                            {
                                                getFieldDecorator(`${type}-place`)(
                                                <Input />
                                                )
                                            }
                                        </Form.Item>
                                                                                                                                             
                                        <Form.Item 
                                         label={`Nombre del contacto durante proceso de ${label}`} 
                                         hasFeedback>
                                            {
                                                getFieldDecorator(`${type}-contactName`,{
                                                    rules:[
                                                        {
                                                            required:true, message:'Ingresa nombre de contacto'
                                                        }
                                                    ]
                                                })(
                                                    <Input />
                                                )
                                            }                                               
                                        </Form.Item>      
                                        <Form.Item label='Teléfono  del contacto' hasFeedback>
                                            {
                                                getFieldDecorator(`${type}-contactPhone`,{
                                                rules:[
                                                    {
                                                        len: 10, message: 'Teléfono de 10 digitos',
                                                        
                                                    },
                                                    {
                                                        required:true, message:'Ingresa el teléfono',

                                                    }
                                                    ]                                                                                                               
                                                })(
                                                    <Input placeholder='5512365478' />
                                                )
                                            }                                    
                                        </Form.Item>
                                    </Col>
                                    <Col md={8} sm={24}>
                                        <Divider orientation='left'>Información Adicional</Divider>
                                        <Form.Item label='Referencias adicionales'>
                                            {
                                                getFieldDecorator(`${type}-extraReferences`)(
                                                    <TextArea />
                                                )
                                            } 
                                        </Form.Item>
                                        <Form.Item label='Instrucciones adicionales'>
                                            {
                                                getFieldDecorator(`${type}-extras`)(
                                                    <TextArea />
                                                )
                                            }  
                                        </Form.Item>
                                        <FormItem label={
                                            <span>
                                                { `¿Deseas que el transportista se encargue de la maniobra de ${label} ?`}
                                                <Tooltip title={tooltipText} className="margin-left-2">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </span>
                                        }>
                                            <p className="bold">Costo Maniobra:<span className="color-primary font-family-primary"> {formatprice.format( maneuverPrice )} MXN </span></p>
                                            {
                                                getFieldDecorator(`${type}-maneuver`, { valuePropName: 'checked' })(
                                                        <Switch checkedChildren="Sí" unCheckedChildren="No" />
                                                )
                                            }
                                        </FormItem>
                                        
                                    </Col>
                                </Row>
                            </Form>    
                        </Col>
                    </Row>
            )
        }
    }
)

ShipmentQuoteForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    chooseMunicipality: PropTypes.func.isRequired,
    chooseAreaCode: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    maneuverPrice: PropTypes.number.isRequired,
    coverage: PropTypes.array.isRequired,
    cpsList: PropTypes.array.isRequired,
    settlements: PropTypes.array.isRequired,
}

export { ShipmentQuoteForm }