import { connect } from 'react-redux'
import { newShipmentActions } from '../../state/actions/newShipment'
import { ShipmentQuoteForm } from '../../components'
import Constants from '../../helpers/constants';
import fetchService from '../../fetchService';
import { message } from 'antd';

const mapStateToProps = ({newShipment}) => {
    const { unitType, originCoverage } = newShipment.routeDetail;
    const { originCPs, originSettlements } = newShipment;
    const maneuverPrice = Constants.unitsTypes[unitType - 1].maneuverPrice;
    return {
        type: 'origin',
        maneuverPrice,
        coverage: originCoverage,
        cpsList: Object.keys(originCPs),
        settlements: originSettlements,
        fields: newShipment.originFields,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (changedFields) => dispatch(newShipmentActions.changeOriginFields(changedFields)),
        addTotal: (a) => dispatch(newShipmentActions.addTotal(a)),
        subtractTotal: (a) => dispatch(newShipmentActions.subtractTotal(a)),
        chooseMunicipality: async (m) => {
            try {
                const s = await fetchService.getSepomexByMunicipality(m);
                await dispatch(newShipmentActions.setOriginCPs(s));
            } catch (error) {
                message.error('Error');
            }
        },
        chooseAreaCode: (ac) => dispatch(newShipmentActions.setOriginSettlements(ac)),
    }
}

const OriginForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShipmentQuoteForm)

export { OriginForm }