import { connect } from 'react-redux';
import { message } from 'antd';
import  { SearchRoutesForm }  from '../../components';
import { landingActions } from '../../state/actions/landing';
import Constants from '../../helpers/constants';

const mapState = ({landing}) => {
    const { loading, zonesList, searching, foundRoutes } = landing;
    const { unitsTypes } = Constants;
    return {
        unitsTypes,
        loading,
        zonesList,
        fields: landing.searchFields,
        searching,
        foundRoutes,
    }
}

const mapDispatch = dispatch => {
    return {
        onChange: (changedFields) => dispatch(landingActions.changeSearchFields(changedFields)),
        searchData: values => {
            try {
                dispatch(landingActions.setSearchFlag(true))
                dispatch(landingActions.searchRoutes(values))
                setTimeout(() => dispatch(landingActions.setSearchFlag(false)), 2000)
            } catch (error) {
                message.error('Error al cargar datos');
            }
        }
    }
}

export default connect(mapState, mapDispatch)(SearchRoutesForm);