import React , { Component } from 'react'
import {  Result, Button } from 'antd';

class SuccessRegistration extends Component{

    render(){
      const { send } = this.props;
      return(
          <Result
          status="success"
          title= {
          <div>
            <p className="normal-title bold">Registro Exitoso</p>
            <p className="normal-subtitle semibold">Solo falta un último paso.</p>
          </div>}
          subTitle={
          <div>
            <p className="normal-paragraph color-secondary align-justify">Hemos enviado un correo con un vínculo de confirmación. Recuerda revisar todas tus bandejas de entrada, promociones, notificaciones, spam, etc. Dale click al vínculo en el correo para confirmar tu cuenta</p>
            <p className="normal-paragraph color-secondary align-justify">Si no encuentras el correo, dale click al botón.</p>
          </div>}
          extra={[
              <div>
                <Button key='login' onClick={() => send()} type="primary">
                  Reenviar Correo
                </Button>
                <p className="margin-top-3 align-justify">Si requieres ayuda envía un whatsapp al <br/><span className="bold">+52 1 55 1388 0066.</span></p>
              </div>
            ]}
          />
          )
        }
      }



export  { SuccessRegistration };