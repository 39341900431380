import React from "react";
import { Modal } from 'antd';

export const SuccessNewRouteContent = () => (
  <div>
    <div className="container-img-circle">
      <img
        src={`${process.env.PUBLIC_URL}/statics/img/Final_Logotipo.svg`}
        className="img-circle"
        alt="Sendengo-Rutas"
      />
    </div>
    <h2 className="normal-title margin-top-3 align-center">
      ¡Gracias, hemos recibido tu sugerencia!
    </h2>
    <p className="align-center">
      Vamos a tratar de incluir tu ruta lo antes posible dentro de las opciones
      de Rutas Sendengo.
    </p>
    <p className="align-center">
      ¿Quieres probar <span className="bold">asignar tu carga</span> bajo el
      modelo tradicional de Sendengo?
    </p>
  </div>
);

const sendSuccessNewRoute = () => Modal.info({
  centered: true,
  width:'initial',
  content: (<SuccessNewRouteContent />),
  okText: 'REGÍSTRATE',
  onOk() {window.location.href=`${process.env.REACT_APP_V4_HOST}/registro/embarcador/?utm_source=Rsendengoemb&utm_medium=modal`}
});

export {
  sendSuccessNewRoute
}