import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Row, Col, Icon, Card } from 'antd';
import './index.less'
import FormatPrice from '../../../helpers/currency';

class RoutesList extends Component {
    render(){
        const { data } = this.props;
        const columns = [
            {
                title: 'ORIGEN',
                dataIndex: 'values.pickup',
                render: zone => (
                  <span>
                    <Icon theme="twoTone" twoToneColor="#0CA669" type="environment" />
                    {` ${zone[0].values.name}`}
                  </span>
                )
              },
              {
                title: 'DESTINO',
                dataIndex: 'values.delivery',
                render: zone => (
                  <span>
                    <Icon theme="twoTone" twoToneColor="#0CA669" type="environment" />
                    {` ${zone[0].values.name}`}
                  </span>
                )
              },
              {
                  title: 'PRECIO',
                  className: 'price-routelist',
                  dataIndex: "values.rate",
                  render: price => <span className="medium color-primary small-title">{FormatPrice.format(price)} MXN</span>
              },
              {
                  title: 'ACCIONES',
                  render: r => (
                    <Button type="primary" disabled={!r.values.available} id={`button-route-list-${r.values.id}`}>
                      <Link id={`button-route-list-${r.values.id}`} to={`/routes/${r.values.id}`}>Elegir ruta</Link>
                    </Button>
                  )
              }
        ]
        return( 
                <Row type="flex" justify="center" className="margin-top-2 margin-bottom-1">   
                    <Col span={24}>
                      <Card>
                        <Table 
                          columns={columns}
                          rowKey={ (r)=> r.values.id }
                          dataSource={data}
                          style={{overflowX:'auto'}}
                          />
                      </Card>                    
                    </Col>
                </Row>         
        )
    }
}
export { RoutesList };