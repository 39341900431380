import React from 'react';
import { Row, Col } from 'antd';
import Constants from '../../../helpers/constants';

const InfoSendengo = () => (
    <section className="container-aboutsendengo">
        <div className="content-aboutsendengo">
            <Row className="align-center">
                <h2 className="normal-title bold">En Sendengo, cuentas con:</h2>
            </Row>
            <Row gutter={24}>
                <Col md={12} xs={24} className="margin-top-3">
                    <span className="card-landing">
                        <div className="container-img">
                            <img src={`${process.env.PUBLIC_URL}/statics/img/gps.png`} style={{width:'232%'}} alt="monitoreo gps"/>
                        </div>
                        <div className="container-text">
                            <p className="big-paragraph"><span className="bold">Monitoreo GPS</span> que nos permite informarte el estatus de tu carga</p>
                        </div>
                    </span>
                </Col>
                <Col md={12} xs={24}  className="margin-top-3">
                    <span className="card-landing">
                        <div className="container-img">
                        <img src={`${process.env.PUBLIC_URL}/statics/img/clock.png`} style={{width:'232%'}} alt="asignar carga a tiempo" />
                        </div>
                        <div className="container-text">
                        <p className="big-paragraph">Si no logramos asignarte la carga en tiempo, <span className="bold">devolvemos tu dinero</span></p>
                        </div>
                    </span>
                </Col>
                <Col md={12} xs={24}  className="margin-top-3">
                    <span className="card-landing">
                        <div className="container-img">
                        <img src={`${process.env.PUBLIC_URL}/statics/img/deliv.png`} style={{width:'232%'}} alt="primeras 8 hrs. sin costo" />
                        </div>
                        <div className="container-text">
                        <p className="big-paragraph">Las primeras 8 horas de carga y descarga no te generan <span className="bold"> ningún costo</span></p>
                        </div>
                    </span>
                </Col>
                <Col md={12} xs={24}  className="margin-top-3">
                    <span className="card-landing">
                        <div className="container-img">
                        <Constants.IconFont  type="icon-document" style={{fontSize:'55px'}}/>
                        </div>
                        <div className="container-text">
                        <p className="big-paragraph">Todo transportista de Sendengo cuenta con: <span className="bold">Póliza de protección a terceros</span></p>
                        </div>
                    </span>
                </Col>
                <Col md={12} xs={24}  className="margin-top-3">
                    <span className="card-landing">
                        <div className="container-img">
                        <Constants.IconFont type="icon-ic_driveravatar" style={{fontSize:'55px'}}/>
                        </div>
                        <div className="container-text">
                        <p className="big-paragraph">Todo transportista de Sendengo cuenta con: <span className="bold">Operadores certificados con Recurso Confiable u Ópalo</span></p>
                        </div>
                    </span>
                </Col>
                <Col md={12} xs={24}  className="margin-top-3">
                    <span className="card-landing">
                        <div className="container-img">
                        <img src={`${process.env.PUBLIC_URL}/statics/img/docu.png`} style={{width:'232%'}} alt="transportistas documentados" />
                        </div>
                        <div className="container-text">
                        <p className="big-paragraph">Todo transportista de Sendengo cuenta con: <span className="bold">Documentación básica registrada</span></p>
                        </div>
                    </span>
                </Col>
            </Row>
        </div>
    </section>
)
export {InfoSendengo}