import { newShipmentTypes } from '../actions/newShipment';

const initialNewShipment = {
    detailsFields:{},
    originFields:{},
    destinationFields:{},
    routeDetail:{},
    originCPs: [],
    originSettlements: [],
    destinationCPs: [],
    destinationSettlements: [],
    step: 0,
};

const setCoverage = zones => {
    const zone = zones[0].values;
    const { coverage } = zone;
    return coverage;
};

const newShipment = (state = initialNewShipment, action) => {
    switch (action.type) {
        
        case newShipmentTypes.FILL_ROUTE_FIELDS:
            return {
                ...state,
                routeDetail: {
                    route: action.route.id,
                    totalRate: action.route.rate,
                    rate: action.route.rate,
                    unitType: action.route.unitType,
                    originCoverage: setCoverage(action.route.pickup),
                    destinationCoverage: setCoverage(action.route.delivery),
                },
            }

        case newShipmentTypes.ADD_TOTAL:
            return {
                ...state,
                routeDetail:{
                    ...state.routeDetail,
                    totalRate: parseFloat(state.routeDetail.totalRate) + action.amount,
                }
            }

        case newShipmentTypes.SUBTRACT_TOTAL:
            return {
                ...state,
                routeDetail:{
                    ...state.routeDetail,
                    totalRate: parseFloat(state.routeDetail.totalRate) - action.amount,
                }
            }

        case newShipmentTypes.FILL_DETAILS_FIELDS:
            return {
                ...state,
                detailsFields: {
                    ...state.detailsFields,
                    ...action.changedFields
                }
            }

        case newShipmentTypes.SET_ORIGIN_CPs:
            return {
                ...state,
                originCPs: action.cps,
            }

        case newShipmentTypes.SET_ORIGIN_SETTLEMENTS:
            return {
                ...state,
                originSettlements: state.originCPs[action.cp],
            }

        case newShipmentTypes.FILL_ORIGIN_FIELDS:
            return {
                ...state,
                originFields: {
                    ...state.originFields,
                    ...action.changedFields
                }
            }

        case newShipmentTypes.SET_DESTINATION_CPs:
            return {
                ...state,
                destinationCPs: action.cps,
            }

        case newShipmentTypes.SET_DESTINATION_SETTLEMENTS:
            return {
                ...state,
                destinationSettlements: state.destinationCPs[action.cp],
            }

        case newShipmentTypes.FILL_DESTINATION_FIELDS:
            return {
                ...state,
                destinationFields: {
                    ...state.destinationFields,
                    ...action.changedFields
                }
            }

        case newShipmentTypes.NEXT_STEP:
            return {
                ...state,
                step: state.step + 1,
            }

        case newShipmentTypes.PREV_STEP:
            return {
                ...state,
                step: state.step - 1,
            }

        case newShipmentTypes.RESET_FIELDS:
            return {
                ...state,
                ...initialNewShipment,
            }

        default:
            return state
    }
}

export { newShipment }