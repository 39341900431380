export const newShipmentTypes = {
    FILL_ROUTE_FIELDS: 'FILL_ROUTE_FIELDS',
    ADD_TOTAL: 'ADD_TOTAL',
    SUBTRACT_TOTAL: 'SUBTRACT_TOTAL',
    FILL_DETAILS_FIELDS: 'FILL_DETAILS_FIELDS',
    SET_ORIGIN_CPs: 'SET_ORIGIN_CPs',
    SET_ORIGIN_SETTLEMENTS: 'SET_ORIGIN_SETTLEMENTS',
    FILL_ORIGIN_FIELDS: 'FILL_ORIGIN_FIELDS',
    SET_DESTINATION_CPs: 'SET_DESTINATION_CPs',
    SET_DESTINATION_SETTLEMENTS: 'SET_DESTINATION_SETTLEMENTS',
    FILL_DESTINATION_FIELDS: 'FILL_DESTINATION_FIELDS',
    RESET_FIELDS: 'RESET_FIELDS',
    NEXT_STEP: 'NEXT_STEP',
    PREV_STEP: 'PREV_STEP',
}

/// newShipment Actions
export const newShipmentActions = {

    fillRouteDetails: (route) => {
        return {
            type: newShipmentTypes.FILL_ROUTE_FIELDS,
            route
        }
    },

    addTotal: (amount) => {
        return {
            type: newShipmentTypes.ADD_TOTAL,
            amount
        }
    },

    subtractTotal: (amount) => {
        return {
            type: newShipmentTypes.SUBTRACT_TOTAL,
            amount
        }
    },

    changeDetailsFields: (changedFields) => {
        return {
            type: newShipmentTypes.FILL_DETAILS_FIELDS,
            changedFields
        }
    },

    setOriginCPs: (cps) => {
        return {
            type: newShipmentTypes.SET_ORIGIN_CPs,
            cps
        }
    },

    setOriginSettlements: (cp) => {
        return {
            type: newShipmentTypes.SET_ORIGIN_SETTLEMENTS,
            cp
        }
    },

    changeOriginFields: (changedFields) => {
        return {
            type: newShipmentTypes.FILL_ORIGIN_FIELDS,
            changedFields
        }
    },

    setDestinationCPs: (cps) => {
        return {
            type: newShipmentTypes.SET_DESTINATION_CPs,
            cps
        }
    },

    setDestinationSettlements: (cp) => {
        return {
            type: newShipmentTypes.SET_DESTINATION_SETTLEMENTS,
            cp
        }
    },

    changeDestinationFields: (changedFields) => {
        return {
            type: newShipmentTypes.FILL_DESTINATION_FIELDS,
            changedFields
        }
    },

    nextStep: () => {
        return {
            type: newShipmentTypes.NEXT_STEP,
        }
    },

    prevStep: () => {
        return {
            type: newShipmentTypes.PREV_STEP,
        }
    },

    resetFields: () => {
        return {
            type: newShipmentTypes.RESET_FIELDS,
        }
    },

}