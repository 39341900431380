export const landingTypes = {
    SET_LANDING_ROUTES_LIST: 'SET_LANDING_ROUTES_LIST',
    SET_LANDING_ZONES_LIST: 'SET_LANDING_ZONES_LIST',
    FILL_SEARCH_FIELDS: 'FILL_SEARCH_FIELDS',
    SET_SEARCH_FLAG: 'SET_SEARCH_FLAG',
    SEARCH_ROUTES: 'SEARCH_ROUTES'
}

/// landing Actions
export const landingActions = {

    setRoutes: (routes) => {
        return {
            type: landingTypes.SET_LANDING_ROUTES_LIST,
            routes
        }
    },

    setZones: (zones) =>{
        return{
            type: landingTypes.SET_LANDING_ZONES_LIST,
            zones
        }
    },

    changeSearchFields: (changedFields) => {
        return{
            type: landingTypes.FILL_SEARCH_FIELDS,
            changedFields
        }
    },

    setSearchFlag: (flag) => {
        return{
            type: landingTypes.SET_SEARCH_FLAG,
            flag
        }
    },

    searchRoutes: (values) => {
        return{
            type: landingTypes.SEARCH_ROUTES,
            values
        }
    },

}