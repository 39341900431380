import SendengoSDK from 'sendengo-sdk'

const apiHost = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : 'http://localhost:8000'
const shipper = SendengoSDK.initializeApp({ apiHost });

/* Admin */
const admin = SendengoSDK.initializeApp({ apiHost })
const adminToken = process.env.REACT_APP_ADMIN_TOKEN
admin.setLocalUserFromApp({ token: adminToken })

export { shipper, admin }