import { connect } from 'react-redux'
import { MyShipmentsBoard } from '../../components';
import { Lo } from '../../helpers';

const mapStateToProps = ({ shipments }) => {
    const board = Lo.getStatusGroup(shipments.shipmentsList);
    return {
        board
    }
}

const ShipmentsBoard = connect(
    mapStateToProps
)(MyShipmentsBoard)

export { ShipmentsBoard }