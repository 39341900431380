import { detailActions } from '../../state/actions/shipmentDetail';
import { shipper } from "./../../fetchService/config";
const { ShipmentModel } = shipper.getLiftModels();

const dispatchOnMessage = (message, dispatch) => {
    const data = JSON.parse(message.data);
    switch (data.event) {
        case 'update':
            const { shipment } = data;
            const updatedShipment = new ShipmentModel(shipment);
            dispatch(detailActions.updateShipment(updatedShipment));
            break;

        default:
            console.log('_');
            break;
    }
}

export default dispatch => (uri) => {
    if (!('WebSocket' in window)) {
        dispatch(detailActions.error({ error: 'WebSocket is not supported by your browser' }));
        return;
    }

    const socket = new WebSocket(uri);
    dispatch(detailActions.connect());
    
    socket.onopen = () => dispatch(detailActions.open({ socket }));
    socket.onerror = () => dispatch(detailActions.error({ error: true }));
    socket.onmessage = evt => dispatchOnMessage(evt, dispatch);
    socket.onclose = () => dispatch(detailActions.close());
};

