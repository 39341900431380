import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routesActions } from '../../state/actions/routes';
import fetchService from '../../fetchService';
import { message } from 'antd';

import { Units } from './Units';
import { Zones } from './Zones';

class View extends Component {

  async componentDidMount(){
    const { setRoutes, setZones } = this.props;
    try {
        const routes = await fetchService.getRoutes();
        const zones = await fetchService.getZones();
        await setRoutes(routes);
        await setZones(zones);
    } catch (error) {
      message.error('Error');
    }

 
  }

  render() {return (
    <div>
      <Units />
      <Zones />
    </div>
  )
}
}

View.propTypes = {
  setRoutes: PropTypes.func.isRequired,
};

const mapState = state => {
  return {
  }
};

const mapDispatch = (dispatch) => {
    return {
      setRoutes: (list) => dispatch(routesActions.setRoutes(list)),
      setZones: (list) => dispatch(routesActions.setZones(list)),
  }
};

export default connect(mapState, mapDispatch)(View);