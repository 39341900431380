import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Tag } from 'antd';
import {  Link } from 'react-router-dom';

import Constants from '../../../helpers/constants';
import { TextHelper } from '../../../helpers';

const {statusValues} = Constants;

/*
bg-ToPay,
bg-loading,
bg-InTransit,
bg-Unload,
bg-Complete
*/
/* headerCard */
const setTag = (status) => <Tag color={statusValues[status].color}>{statusValues[status].text}</Tag>;
const setTitleCard = ({ reference, status }) => <div className="bold font-family-primary">{setTag(status)} # {reference}</div>
// Quotes
const showQuote = (quotes, direction) => {
    const quote = quotes[0].values;
    const wordDirection = direction ? 'A: ' : 'De: ';
    const quoteAdddress = TextHelper.getQuoteAddress(quote.address.values);
    const qDate = new Date(quote.datetime)
    return <p><Icon type='environment' theme="twoTone" twoToneColor="#0CA669"/> {`${wordDirection}${quoteAdddress}`}<br/>{qDate.toLocaleString('es-MX')}</p>
}

const ShipmentCard = ({shipment}) => (
    <Card style={{marginBottom:"10px"}} title={setTitleCard(shipment)} extra={<Link to={`/shipments/${shipment.id}`}>Ver más</Link>}>
        {showQuote(shipment.pickup)}
        {showQuote(shipment.delivery, true)}
        <p><Constants.IconFont type="icon-truck" style={{color:"#0CA669"}}/> {TextHelper.getUnitType(shipment.route.values)}</p>
    </Card>)

ShipmentCard.propTypes = {
    shipment: PropTypes.object.isRequired,
}

export { ShipmentCard };