import { routesTypes } from '../actions/routes'

const initialRoutes = {
    routesList: [],
    zonesList: [],
    visible: true,
}

const routes = (state = initialRoutes, action) => {
    switch (action.type) {
        
        case routesTypes.SET_ROUTES_LIST:
            return {
                ...state,
                routesList: action.routes
            }

        case routesTypes.SET_ZONES_LIST:
            return {
                ...state,
                zonesList: action.zones
            }

        case routesTypes.OPEN_ZONES_LIST:
            return {
                ...state,
                visible: true
            }
        
        case routesTypes.CLOSE_ZONES_LIST:
            return {
                ...state,
                visible: false
            }

        default:
            return state
    }
}

export { routes }