import { connect } from 'react-redux'
import { routesActions } from '../../state/actions/routes';
import { ZoneDrawer } from '../../components';  

const mapStateToProps = ({ routes }) => {
    const { zonesList, visible } = routes;  
    return {
        zonesList,
        visible
    }
}

const mapDispatchToProps = dispatch => {  
    return {
        closeDrawer: () => dispatch(routesActions.closeDrawer()),
    }
}

const Zones = connect(
    mapStateToProps,
    mapDispatchToProps
)(ZoneDrawer)

export { Zones }