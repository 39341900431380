export const routesTypes = {
    SET_ROUTES_LIST: 'SET_ROUTES_LIST',
    SET_ZONES_LIST: 'SET_ZONES_LIST',
    OPEN_ZONES_LIST: 'OPEN_ZONES_LIST',
    CLOSE_ZONES_LIST: 'CLOSE_ZONES_LIST',
}

/// routes Actions
export const routesActions = {

    setRoutes: (routes) => {
        return {
            type: routesTypes.SET_ROUTES_LIST,
            routes
        }
    },
    
    setZones: (zones) =>{
        return{
            type: routesTypes.SET_ZONES_LIST,
            zones
        }
    },

    openDrawer: () =>{
        return{
            type: routesTypes.OPEN_ZONES_LIST,
        }
    },

    closeDrawer: () =>{
        return{
            type: routesTypes.CLOSE_ZONES_LIST,
        }
    },

}