import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Divider, Spin, Alert, Row } from 'antd';
import { SuccessRegistration } from './SuccessRegistration';

import {  Link } from 'react-router-dom'

const RegisterForm = Form.create({
    name: 'register-form',
    onFieldsChange(props, changedFields){

    },
})(
    class extends Component {
        state = {
            confirmDirty: false,
            form: null
        };
        
        handleSubmit = e =>{
            e.preventDefault();
            const { form, post } = this.props;
            form.validateFields((err, values) =>{
              if (!err) {
                post(values)
                this.setState({ form: values });
              }
            });
        };

        resend = () =>{
            const { form } = this.state;
            const { sendConfirmation } = this.props;
            sendConfirmation(form)
        };

        handleConfirmBlur = e => {
            const { value } = e.target;
            this.setState({ confirmDirty: this.state.confirmDirty || !!value });
        };
        
        compareToFirstPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && value !== form.getFieldValue('password')) {
                callback('No coinciden las contraseñas');
            } else {
                callback();
            }
        };
        
        validateToNextPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && this.state.confirmDirty) {
                form.validateFields(['confirm'], { force: true });
            }
            callback();
        };

        resetForm = () => {
            this.props.goToLogin();
        }

        render(){
            const { getFieldDecorator } = this.props.form;
            const { status } = this.props;
            return(
                <div>
                    {
                        status.success
                        ?
                        <SuccessRegistration send={this.resend}/>
                        :
                        <Form layout='vertical' onSubmit={this.handleSubmit}>
                        <h1 className="normal-subtitle bold">Crea Tu Cuenta</h1>
                        <Form.Item hasFeedback label="Nombre" style={{paddingBottom:'0'}}>
                            {
                                getFieldDecorator("first_name",{
                                 rules:[
                                     {required: true, message:'Ingresa tu nombre'}
                                 ]   
                                })(
                                    <Input  className="margin-bottom-0"/>
                                )
                            }
                           
                        </Form.Item>
                        <Form.Item hasFeedback label="Apellido" style={{paddingBottom:'0'}}>
                            {
                                getFieldDecorator("last_name", {
                                    rules:[
                                        { required: true, message: 'Ingresa tu apellido'}
                                    ]
                                })(
                                    <Input  className="margin-bottom-0"/>
                                )
                            }
                           
                        </Form.Item>
                        <Form.Item hasFeedback label="Correo electrónico" style={{paddingBottom:'0'}}>
                            {
                                getFieldDecorator("email", {
                                    rules:[
                                        { required: true, message : 'Ingresa tu correo electrónico'},
                                        { type: 'email', message: 'Ingresa un correo valido'}
                                    ]
                                })(
                                    <Input placeholder="Ej: tunombre@empresa.com" className="margin-bottom-0" />
                                )
                            }
                            
                        </Form.Item>
                        <Form.Item hasFeedback label="Contraseña" style={{paddingBottom:'0'}}>
                        {
                            getFieldDecorator('password', {
                                rules: [
                                    { required: true, message: 'Ingresa una contraseña'},
                                    { validator: this.validateToNextPassword },
                                ]
                            })(
                                <Input type="password" className="margin-bottom-0" />
                            )
                        }
                         
                        </Form.Item>
                        <Form.Item hasFeedback label="Confirmación de contraseña" style={{paddingBottom:'0'}}>
                        {
                            getFieldDecorator('confirm', {
                                rules: [
                                    { required: true, message: 'Confirma tu contraseña'},
                                    { validator: this.compareToFirstPassword },
                                ]
                            })(
                                <Input className="margin-bottom-0" type="password" onBlur={this.handleConfirmBlur}/>
                            )
                        }
                        </Form.Item>
                        <Divider orientation="left">Compañia</Divider>
                        <Form.Item label="Nombre de tu empresa" style={{paddingBottom:'0'}}>
                        {
                            getFieldDecorator('company_name', { initialValue: '' })(
                                <Input className="margin-bottom-0"/>
                            )
                        }
                        </Form.Item>
                        <Form.Item hasFeedback label="Teléfono de contacto" style={{paddingBottom:'0'}}>
                        {
                            getFieldDecorator('phone', {
                                rules: [
                                    { required : true, message: 'Ingresa un telefóno'},
                                    {
                                        len: 10, message: 'Teléfono de 10 digitos'
                                    }
                                ]
                            })(
                                <Input type="number" className="margin-bottom-0" />
                            )
                        }
                            
                        </Form.Item>
                        <div style={{ textAlign: 'center', marginBottom: 10, }}>
                            {
                                status.error &&
                                <Alert type="error" message="Error text" banner />
                            }
                            {
                                status.loading &&
                                <Spin size="large" />
                            }    
                        </div>
                        <Form.Item style={{paddingBottom:'0'}}>                    
                            <Button type="primary" htmlType="submit" size="large" block >REGISTRARME</Button>
                        </Form.Item>
                        <Row  type="flex" justify="center">
                        <p className="medium-paragrap bold">¿Ya tienes cuenta en Sendengo?<Link to="login"> INICIA SESIÓN</Link></p>
                    </Row>
                    </Form>
                    }
                </div>
            )
        }
    }

)

RegisterForm.propTypes = {
    post: PropTypes.func.isRequired,
    status: PropTypes.object.isRequired,
    goToLogin: PropTypes.func.isRequired,
    sendConfirmation: PropTypes.func.isRequired,
  }


export { RegisterForm };