import { shipmentsTypes } from '../actions/shipments'

const initialShipments = {
    loading: false,
    connected: false,
    error: null,
    message: null,
    shipmentsList: [],
}

const shipments = (state = initialShipments, action) => {
    switch (action.type) {
        
        case shipmentsTypes.CONNECTING:
            return {
                ...state,
                loading: true,
            };

        case shipmentsTypes.OPENED:
            return {
                ...state,
                connected: true,
                loading: false,
                ws: action.payload.socket,
            };
            
        case shipmentsTypes.MESSAGED:
            return {
                ...state,
                ...action.payload,
            };

        case shipmentsTypes.ERROR:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };

        case shipmentsTypes.CLOSED:
            return {
                ...state,
                loading: false,
                connected: false,
            };

        case shipmentsTypes.SET_SHIPMENTS_LIST:
            return {
                ...state,
                shipmentsList: action.shipments
            };

        case shipmentsTypes.UPDATE_SHIPMENTS_LIST:
            return {
                ...state,
                shipmentsList: state.shipmentsList.map(s => {
                    if (s.values.id === action.shipment.values.id) {
                        s = action.shipment; 
                    }
                    return s;
                })
            };

        default:
            return state;
    }
}

export { shipments }