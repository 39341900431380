import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import SiderMenu from './Side'
const { Header, Content } = Layout;

const SessionRoute = ({ component, exact = false, path, authenticated }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <SiderMenu/>
    <Layout>
      <Header style={{ background: '#fff', padding: 0 }} />
        <Content style={{ margin: '0 16px' }}>
          <Route
            exact={exact}
            path={path}
            render={props => (
              authenticated ? (
                React.createElement(component, props)
              ) : (
                <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }}/>
              )
            )}
          />
        </Content>
    </Layout>
  </Layout>
);

const { object, bool, string, func } = PropTypes;

SessionRoute.propTypes = {
  component: func.isRequired,
  exact: bool,
  path: string.isRequired,
  authenticated: bool.isRequired,
  location: object
};

export default SessionRoute;