import React , { Component } from 'react'
import {  Result, Button } from 'antd';

import {  Link } from 'react-router-dom'

class MissingPage extends Component{
  render(){
    return(
      <Result
      status="404"
      title="404"
      subTitle="Lo sentimos la página no existe."
      extra={
      <Button type="primary">  
          <Link to="/">
          REGRESAR
          </Link>
      </Button>}
    />
  )
  }
 
}



export  { MissingPage };