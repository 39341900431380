import React from 'react';
import { Row, Col } from 'antd';

const FooterLanding = () => (
    <footer className="container-footer">
        <Row gutter={16} className="content-footer" style={{margin:"auto"}} >
            <Col md={7} sm={24} style={{padding: '0 32px'}}>
                <img src={`${process.env.PUBLIC_URL}/statics/img/Final_Logotipo_Blanco_Verde.svg`} alt="Sendengo" style={{width:"41%"}}/>
                <div className="color-white">
                  <p className="small-paragraph">Contactános</p>
                  <a href="tel:5555108654" id="tel-landing" className="color-white small-paragraph">5555108654</a>
                  <p className="small-paragraph">Sendengo, Paseo de la Reforma #23,</p>
                  <p className="small-paragraph">Tabacalera,Ciudad de México</p>
                </div>
            </Col>
            <Col md={10} sm={24}  style={{padding: '0 32px'}}>
                <h2 className="color-white">¿Buscas otro tipo de unidades?</h2>
                <span className="color-white">Si tu operación es más grande y deseas empezar una relación comercial corporativa con nosotros. Registrate en <a href="https://www.sendengo.com/?utm_source=RSendengo&utm_medium=modal" target="_blank" rel="noopener noreferrer">www.sendengo.com</a> y contacta con nuestros asesores.</span>
            </Col>
            <Col md={7} sm={24} className="container-social"  style={{padding: '0 32px'}}>
                <a href="https://www.facebook.com/sendengo/" className="margin-bottom-3" target="_blank" rel="noopener noreferrer" id='social-link-facebook'><img src={`${process.env.PUBLIC_URL}/statics/img/facebook.svg`} style={{width:'10%'}} alt="facebook sendengo" id='social-img-facebook'></img></a>
                <a href="https://twitter.com/sendengo" className="margin-bottom-3" target="_blank" rel="noopener noreferrer" id='social-link-twitter'><img src={`${process.env.PUBLIC_URL}/statics/img/gorjeo.svg`} style={{width:'10%'}} alt="twitter sendengo" id='social-img-twitter'></img></a>
                <a href="https://www.linkedin.com/company/sendengo/" className="margin-bottom-3"  target="_blank" rel="noopener noreferrer" id='social-link-linkedin'><img src={`${process.env.PUBLIC_URL}/statics/img/linkedin.svg`} style={{width:'10%'}} alt="linkedin sendengo" id='social-img-linkedin'></img></a>
            </Col>
        </Row>
        <div className="container-copyright"> 
            <div className="content-copyright">
                <span>© Rutas Sendengo, Todos los derechos reservados</span>  
            </div>
        </div>
    </footer>
)
export {FooterLanding}