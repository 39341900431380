import React, { Component } from "react";
import { Row, Card, message } from "antd";
import { connect } from "react-redux";

import { loginActions } from "../state/actions/login";
import fetchService from "../fetchService";
import { RegisterForm } from "../components";
import {  Link } from 'react-router-dom'

const logosvg = `${process.env.PUBLIC_URL}/statics/img/Final_Isotipo.svg`;

class View extends Component {

  // TODO move to container for Form
  async registerShipper(form) {
    const { postRequest, postSuccess, postError } = this.props;
    postRequest()
    try {
      const data = await fetchService.registerShipper(form);
      await postSuccess(data)
    } catch (error) {
      await postError(error)
    }
  }

  async sendConfirmation(form) {
    try {
      const text = await fetchService.resendConfirmation(form);
      message.success(text);
    } catch (error) {
      message.error(error);
    }
  }

  goToLogin(){
    this.props.history.push('/');
    this.props.goToLogin();
  }

  render() {
    const { status } = this.props;
    return (
      <Row
        type="flex"
        justify="center"
        className="margin-top-3 margin-bottom-1"
      >
        <div style={{width:'100%'}}>
         <Row type="flex" justify="center" align="middle">
            <Link to="/" id="link-landing-register"><img className="margin-bottom-3" src={logosvg} alt="logo" id="link-image-landing-register" onClick={this.props.onClickWelcome} style={{width:'100%', height:'76.1562px'}}/></Link>
            </Row>
        <Row type="flex" justify="center" align="middle" >
          <Card style={{width:'410px'}}>
            <RegisterForm
              post={(form) => this.registerShipper(form)}
              status={status}
              sendConfirmation={(form) => this.sendConfirmation(form)}
              goToLogin={() => this.goToLogin()}/>
          </Card>
        </Row>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const status = {
    loading: state.login.registerLoading,
    success: state.login.registerSuccess,
    error: state.login.registerError
  };
  return {
    status
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postRequest: () => dispatch(loginActions.postRegisterRequest()),
    postSuccess: f => dispatch(loginActions.postRegisterSuccess(f)),
    postError: e => dispatch(loginActions.postRegisterError(e)),
    goToLogin: () => dispatch(loginActions.goToLogin()),
  };
};

const RegisterView = connect(
  mapStateToProps,
  mapDispatchToProps
)(View);

export { RegisterView };
