import { connect } from 'react-redux';
import { ShipmentDetailCard } from '../../components';


const mapState = ({ shipmentDetail }) => {
    const { details } = shipmentDetail;
    return {
        shipment: details.values ? details.values : {}
    }
  };

const Detail = connect(
    mapState
)(ShipmentDetailCard)

export { Detail }