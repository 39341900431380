import { createAction } from 'redux-actions';

export const shipmentDetailTypes = {
    CONNECTING: 'SOCKET_CONNECTING',
    OPENED: 'SOCKET_OPENED',
    MESSAGED: 'SOCKET_MESSAGED',
    ERROR: 'SOCKET_ERROR',
    CLOSED: 'SOCKET_CLOSED',
    SET_SHIPMENT_DETAIL: 'SET_SHIPMENT_DETAIL',
    UPDATE_SHIPMENT_DETAIL: 'UPDATE_SHIPMENT_DETAIL',
}

/// shipment Detail Actions
export const detailActions = {
    // Sockets Actions
    connect: createAction(shipmentDetailTypes.CONNECTING),
    open: createAction(shipmentDetailTypes.OPENED),
    message: createAction(shipmentDetailTypes.MESSAGED),
    error: createAction(shipmentDetailTypes.ERROR),
    close: createAction(shipmentDetailTypes.CLOSED),

    setShipmentDetail: (shipment) => {
        return {
            type: shipmentDetailTypes.SET_SHIPMENT_DETAIL,
            shipment
        }
    },

    updateShipment: (shipment) => {
        return {
            type: shipmentDetailTypes.UPDATE_SHIPMENT_DETAIL,
            shipment
        }
    },
}