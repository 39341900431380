import { sessionService } from 'redux-react-session';
import LogRocket from 'logrocket';
import { shipper } from "../fetchService/config";

const saveSession = async user => {
    const { token, id, first_name, last_name, email, phone, user_type, } = user;
    LogRocket.identify(id, {
        name: `${first_name} ${last_name}`,
        email,
        phone,
        type: user_type
    });
    await sessionService.saveSession({ token });
    await sessionService.saveUser(user);
}

const getUser = async() => {
    const user = await sessionService.loadUser();
    const { id, first_name, last_name, email, phone, user_type, } = user;
    LogRocket.identify(id, {
        name: `${first_name} ${last_name}`,
        email,
        phone,
        type: user_type
    });
    await shipper.setLocalUserFromApp(user);
    window.dataLayer.push({userID: user.id})
    return user;
}

const validateSession = async(session)=> {
    if (session) {
        await getUser();
        return true;
    }
    return false;
}

const deleteSession = () => sessionService.deleteSession()

export default {
    saveSession,
    getUser,
    deleteSession,
    validateSession,
}