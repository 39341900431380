import Constants from './constants';

const transformIfExists = (value, func) =>
  isBlank(value)
    ? value
    : func(value)

const isBlank = (value) =>
  value === '' || value === undefined || value === null

const toUpperCaseTransformation = (_value) => `${_value}`.toUpperCase()

const capitalizeTransformation = (_value) =>
  `${_value}`.charAt(0).toUpperCase() + `${_value}`.substr(1)

const getMunicipality = (direction) => direction.municipality ? direction.municipality : 'S/M'

const getState = (direction) => direction.state ? direction.state : 'S/E'

const getShortState = (direction) => direction.shortState ? toUpperCaseTransformation(direction.shortState) : 'S/E'

const getConstantState = ({state}) => Constants.statesValues[state - 1].name;

const getConstantShortState = ({state}) => Constants.statesValues[state - 1].shortName;

const getRoad = (direction) => direction.road ? direction.road : 'S/C'

const getSettlement = (direction) => direction.settlement ? direction.settlement : 'S/C'

const getAreaCode = (direction) => direction.areaCode ? direction.areaCode : 'S/CP'

const getStringValue = field => {
  const value = field ? field.value : 'N/A'
  return value
}

const getShortDirection = (direction) => {
  const municipality = getMunicipality(direction)
  const state = getShortState(direction)
  return `${municipality}, ${state}`
}

const getQuoteAddress = (address) => {
  const municipality = getMunicipality(address)
  const state = getConstantShortState(address)
  return `${municipality}, ${state}`
}

const getCompleteDirection = (direction) => {
  const road = getRoad(direction)
  const municipality = getMunicipality(direction)
  const state = getState(direction)
  const settlement = getSettlement(direction)
  const areaCode = getAreaCode(direction)
  return `${road}, ${settlement}, ${municipality}, ${state}, C.P. ${areaCode}`
}

const getCleanDirectionArray = (direction) => {
  const directionArray = direction.split(',')
  let cleanDirectionArray = []
  if (directionArray.length > 1) {
    cleanDirectionArray = directionArray.splice(-2)
  } else {
    cleanDirectionArray = directionArray[0]
  }
  return cleanDirectionArray
}

const getUnitType = ({unitType}) =>
  Constants.unitsTypes[unitType - 1].text

const getShortUnitType = ({unitType}) =>
Constants.unitsTypes[unitType - 1].short

export default {
  transformIfExists,
  isBlank,
  getStringValue,
  toUpperCaseTransformation,
  capitalizeTransformation,
  getMunicipality,
  getState,
  getConstantState,
  getShortState,
  getQuoteAddress,
  getRoad,
  getSettlement,
  getAreaCode,
  getShortDirection,
  getCompleteDirection,
  getCleanDirectionArray,
  getUnitType,
  getShortUnitType,
}