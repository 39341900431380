import React from 'react';
import { Row, Col, Button } from 'antd';
import Constants from '../../../helpers/constants';

const Pymes = () => (
    <section className="container-pymes">
        <Row gutter={24} type="flex"  className="content-pymes" style={{margin:"auto"}}>
            <Col md={14} xs={24}>
                <img className="img-pymes" src={`${process.env.PUBLIC_URL}/statics/img/man_log.jpg`} alt="transportes para pyMES"/>
            </Col>
            <Col md={10} xs={24} className="container-pymes-text padding-bottom-5">
                <h2 className="normal-title bold">Transporte para PyMEs</h2>
                <p className= "margin-bottom-2 big-paragraph">Rutas Sendengo nace como respuesta a las necesidades de transporte de <span className="bold">pequeñas y medianas empresas.</span></p>
                <p className= "margin-bottom-2 big-paragraph">Buscamos brindar una <span className="bold">solución fácil</span> de usar para quienes buscan fletes competitivos y desean transportar carga esporádica o periódicamente.</p>
                <p className= "margin-bottom-2 big-paragraph">Evita perder el tiempo con negociaciones incomodas y pon tu carga en las manos de nuestra <span className="bold">comunidad profesional</span> de transportistas.</p>
                <p className= "margin-bottom-2 big-paragraph">Las unidades actualmente disponibles son: </p>
                <ul className="margin-bottom-3 list-style-none padding-0">
                    <li><Constants.IconFont type="icon-truck" style={{color:'#0CA669'}}/> Camioneta 3.5 Ton</li>
                    <li><Constants.IconFont type="icon-truck" style={{color:'#0CA669'}}/> Camioneta 1.5 Ton</li>
                </ul>
                <Button size="large" type="primary" href="https://sendengo-10-64.ghost.io/rutas-sendengo/" target="_blank" rel="noopener noreferrer">Conoce más</Button>
            </Col>
        </Row>
    </section>
)
export {Pymes}