import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Steps, Button, Card } from 'antd';
const { Step } = Steps;

class  NewShipmentSteps extends Component{

    render(){
        const { currentStep, steps, nextStep, prevStep, confirmShipment, form } = this.props;
        return (
          <Card>
            <Steps current={currentStep} type="navigation">
              {
                steps.map(item => (
                <Step key={item.title} title={item.title} icon={item.icon} />))
              }
            </Steps>
            <div className="steps-content">{steps[currentStep].content}</div>
            <div className="steps-action float-right padding-5">
            {currentStep > 0 && (
                <Button  className="button-secondary margin-right-1 width-button-step" onClick={prevStep} size="large">
                  REGRESAR
                </Button>
              )}
              {currentStep < steps.length - 1 && (
                <Button type="primary"  className="width-button-step" onClick={nextStep} size="large">
                  SIGUIENTE
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button type="primary"  className="width-button-step" onClick={() => confirmShipment(form)} size="large">
                  CONFIRMAR Y PAGAR
                </Button>
              )}
          
            </div>
          </Card>
        );
    }
}

NewShipmentSteps.propTypes = {
    currentStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    confirmShipment: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
};

export { NewShipmentSteps };