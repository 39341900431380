import React, { Component } from 'react';
import { Row, Col, Modal, message } from 'antd';
import { PaymentModalContent }  from '../../components/molecules/PaymentModalContent';
import { connect } from 'react-redux';
import { newShipmentActions } from '../../state/actions/newShipment';
import Steps from './Steps';
import fetchService from '../../fetchService';

class View extends Component {
  async componentDidMount(){
    const { match: { params }, fillRoute } = this.props;
    try {
      const route = await fetchService.getRouteInfo(params.id);
      fillRoute(route.values);
    } catch (error) {
      message.error('Error');
    }
  }

  render(){
    const formatprice= new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
    const { newShipmentRoute, goToDetail } = this.props;
    return(         
      <Row type="flex"  className="content-views">
          <Col md={8} sm={24}>
            <h1 className="normal-title semibold">Tu Carga</h1>
          </Col>
          <Col md={16} sm={24} style={{ textAlign: "right" }}>
            <h2 className="normal-title bold color-primary">Total a pagar: { formatprice.format( newShipmentRoute.totalRate)} MXN</h2>
          </Col>
          <Col span={24}>
            <Steps finish={(s) => goToDetail(s)}/>
          </Col>
      </Row>
    )
  }
  
}

const mapState = ({newShipment}) => {
  return {
    newShipmentRoute: newShipment.routeDetail
  }
}


const mapDispatch = (dispatch, {history}) => {
  const goToHelp =() => {
    history.push(`/help`); 
    Modal.destroyAll();    
  };
  return {
    fillRoute: (s) => dispatch(newShipmentActions.fillRouteDetails(s)),
    goToDetail: async(newShipment) => {
      await Modal.success({
        title: (<span className="small-title bold ">Realiza Tu Pago</span>),
        width:1000,
        content: (
          <PaymentModalContent
            paymentReference={newShipment.payment_reference}
            goToHelp={() => goToHelp()}
          />
        ),
        okText: 'Ir al detalle',
        onOk: async() => {
          await dispatch(newShipmentActions.resetFields());
          history.push(`/shipments/${newShipment.id}`);
        },
      });
    },
  }
}

export default connect(mapState, mapDispatch)(View);