import React from 'react';
import { Card, Tabs, Row, Empty, Button } from 'antd';
import "./index.less";
import { RoutesList } from '../../molecules/RoutesList';
import { RoutesCarousel }from '../../molecules/RouteCarousel';
import Constants from '../../../helpers/constants';

const { TabPane } = Tabs;

const getVolumen = type => {
    let v = 0;
    switch (type) {
        case '1.5':
            v = 5;
            break;

        case '3.5':
            v = 15;
            break;

        default:
            v = 0;
            break;
    }
    return v;
};

const VansCard = ({unitsTabs, openDrawer }) => {
    return(
        <Row className="content-views">
            <h1 className="normal-title semibold">Rutas disponibles</h1>
            <RoutesCarousel />
            <Card>
                <div className="open_drawer">
                    <p className="semibold font-family-primary"> <span className="color-error">*</span> Selecciona, unidad, zona y tarifa; más adelante podrás ingresar detalles específicos de tu carga.</p>
                    <Button type="primary" onClick={openDrawer} size="large">
                        <Constants.IconFont type="icon-mappin" icon="icon-map"/>ZONAS 
                    </Button>
                </div>
                {
                    unitsTabs.length > 0
                    ?
                    <Tabs defaultActiveKey="1">
                        {
                            unitsTabs.map(tab => (
                                <TabPane
                                    tab={
                                        <span className="small-title">
                                            <Constants.IconFont type="icon-truck" />
                                            {tab[0]}
                                        </span>
                                    }
                                    key={tab[0]}>
                                    <p className="semibold"><span className="color-primary"><Constants.IconFont type="icon-logistics-volume" /></span> Máximo <span className="bold font-family-primary"> {getVolumen(tab[0])} </span>metros cúbicos</p>
                                    <RoutesList data={tab[1]}/>
                                </TabPane>
                            ))
                        }
                    </Tabs>
                    :
                    <Empty/>
                }
            </Card>
        </Row>
    )
}
export { VansCard };