import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import {  Link } from 'react-router-dom';
import mySession from '../../../session';
import './index.less';


const { Sider } = Layout;
const { Item } = Menu;
const whiteLogo = `${process.env.PUBLIC_URL}/statics/img/Final_Logotipo_Blanco_Verde.svg`;

class SiderMenu extends Component {

  render() {
    const { menuKey } = this.props;
    return (
        <Sider breakpoint="lg" collapsedWidth="0" className="Sider-Menu">
          <div className="padding-2">
            <img src={whiteLogo} alt="logo" />
          </div>
          <Menu theme="dark" selectedKeys={[menuKey]} mode="inline">
            <Item key="1">
              <Link to="/routes">
                <Icon type="environment" />
                <span>Rutas</span>
              </Link>
            </Item>
            <Item key="2">
              <Link to="/shipments">
                <Icon type="carry-out" />
                <span>Embarques</span>
              </Link>
            </Item>
            <Item key="3">
              <Link to="/help">
                <Icon type="question-circle" />
                <span>Ayuda</span>
              </Link>
            </Item>
            <Item onClick={mySession.deleteSession}>
              <Icon type="logout" />
              <span>Salir</span>   
            </Item>
          </Menu>
        </Sider>
    );
  }
}

export { SiderMenu };
