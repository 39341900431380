import { shipmentDetailTypes } from '../actions/shipmentDetail'

const initialDetail = {
    loading: false,
    connected: false,
    error: null,
    message: null,
    details: {}
}

const shipmentDetail = (state = initialDetail, action) => {
    switch (action.type) {
        
        case shipmentDetailTypes.CONNECTING:
            return {
                ...state,
                loading: true,
            };

        case shipmentDetailTypes.OPENED:
            return {
                ...state,
                connected: true,
                loading: false,
                ws: action.payload.socket,
            };
            
        case shipmentDetailTypes.MESSAGED:
            return {
                ...state,
                ...action.payload,
            };

        case shipmentDetailTypes.ERROR:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };

        case shipmentDetailTypes.CLOSED:
            return {
                ...state,
                loading: false,
                connected: false,
            };

        case shipmentDetailTypes.SET_SHIPMENT_DETAIL:
            return {
                ...state,
                details: action.shipment
            };

        case shipmentDetailTypes.UPDATE_SHIPMENT_DETAIL:
            return {
                ...state,
                details: {
                    ...state.details,
                    ...action.shipment
                },
            };

        default:
            return state;
    }
}

export { shipmentDetail }