import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import esEs from 'antd/es/locale/es_ES';

import "./App.less";

import { LoginView } from "./views/LoginView";
import { RegisterView } from "./views/RegisterView";
import Routes from './views/Routes';
import ShipmentDetail from './views/ShipmentDetail';
import Help from './views/Help';
import NewShipment from './views/NewShipment';
import Shipments from './views/Shipments';
import SessionRoute from './views/SessionRoute';
import {MissingPage} from './views/MissingPage';
import Landing from './views/Landing';

// This component handles the App template used on every page.
class App extends Component {

  render() {
    const { authenticated, checked } = this.props;
    return (
      <ConfigProvider locale={esEs}>
      <div>
        { checked &&
          <Switch>
            <Route exact path="/"
            render={ props =>
              authenticated
              ?
              <Redirect to={{
                pathname: '/routes',
                state: { from: props.location }
              }}/>
              :
              React.createElement(Landing, props)
            }/>
            <SessionRoute exact path="/routes" component={Routes} authenticated={authenticated}/>
            <SessionRoute exact path="/routes/:id" component={NewShipment} authenticated={authenticated}/>
            <SessionRoute exact path="/shipments" component={Shipments} authenticated={authenticated}/>
            <SessionRoute exact path="/shipments/:id" component={ ShipmentDetail } authenticated={authenticated}/>
            <SessionRoute exact path="/help" component={ Help } authenticated={authenticated}/>
            <Route path="/login" component={LoginView}/>
            <Route path="/register" component={RegisterView}/>
            <Route render={() => <MissingPage/>} />
          </Switch>
        }
      </div>
      </ConfigProvider>
    );
  }
}

const { bool } = PropTypes;

App.propTypes = {
  authenticated: bool.isRequired,
  checked: bool.isRequired
};

const mapState = state => {
  const { session } = state;
  return {
    checked: session.checked,
    authenticated: session.authenticated
  }
};

export default connect(mapState)(App);