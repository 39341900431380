import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TextHelper, FormsHelper } from '../../../helpers';

class ShipmentConfirmationDetail extends Component{
    render(){
        const { details, origin, destination , maneuverPrice, rate, totalRate } = this.props;
        const formatprice= new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });
        // DETAILS ///
        const description = TextHelper.getStringValue(details.description);
        const weight = TextHelper.getStringValue(details.weight);
        // ORIGIN QUOTE ///
        const originQuote = FormsHelper.getQuoteValues(origin);
        const originDate= new Date(originQuote.datetime);
        const originManuever = originQuote.maneuver;
        // DESTINATION QUOTE ///
        const destinationQuote = FormsHelper.getQuoteValues(destination);
        const destinationDate= new Date(destinationQuote.datetime);
        const destinationManuever = destinationQuote.maneuver; 
        return(
            <Row gutter={16} className="margin-top-2 padding-top-5 ">
                <h1 className="bold small-title margin-bottom-5">Confirmación de Tu Carga</h1> 
                <Row>
                    <p className="bold font-family-primary big-paragraph color-primary">Detalles</p>
                    <Col span={12}>
                        <p className="bold">Descripcion: <span className="regular">{description}</span></p>
                    </Col>
                    <Col span={12}>
                        <p className="bold">Peso: <span className="font-family-primary medium">{weight} Ton</span></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} sm={24}>
                        <p className="bold font-family-primary big-paragraph color-primary">Información de origen</p>
                        <p className="bold">Direccion: <span className="regular">{originQuote.address}</span></p>
                        <p className="bold">Nombre del lugar: <span className="regular">{originQuote.place ? originQuote.place: "No especificado"}</span></p>
                        <p className="bold">Nombre del contacto:<span className="regular"> {originQuote.contactName}</span></p>
                        <p className="bold">Teléfono del contacto: <span className="regular font-family-primary medium">{originQuote.contactPhone}</span></p>
                        <p className="bold">Referencias adicionales: <span className="regular">{originQuote.extraReferences ? originQuote.extraReferences: "No especificadas" }</span></p>
                        <p className="bold">Instrucciones adicionales: <span className="regular">{originQuote.extras ? originQuote.extras : "No especificadas"}</span></p>
                        <p className="bold">Fecha y hora carga: <span className="medium font-family-primary">{originDate.toLocaleString('es-MX')}</span></p>
                    </Col> 
                    <Col md={8} sm={24}>
                        <p className="bold font-family-primary big-paragraph color-primary">Información de destino</p>
                        <p className="bold">Dirección: <span className="regular">{destinationQuote.address}</span></p> 
                        <p className="bold">Nombre del lugar: <span className="regular">{destinationQuote.place ?  destinationQuote.place : "No especificado"} </span></p>                      
                        <p className="bold">Nombre del contacto: <span className="regular">{destinationQuote.contactName }</span></p>                        
                        <p className="bold">Teléfono del contacto: <span className="font-family-primary medium">{ destinationQuote.contactPhone}</span> </p>                 
                        <p className="bold">Referencias adicionales: <span className="regular">{destinationQuote.extraReferences ? destinationQuote.extraReferences : "No especificadas"  }</span> </p>                       
                        <p className="bold">Instrucciones adicionales: <span className="regular">{destinationQuote.extras ? destinationQuote.extras :"No especificadas"}</span></p>
                        <p className="bold">Fecha y hora descarga: <span className="font-family-primary medium">{destinationDate.toLocaleString('es-MX')}</span ></p>
                    </Col>
                    <Col md={8} sm={24}>
                        <p  className="bold font-family-primary big-paragraph color-primary">Precio</p>
                        <p className="bold">Precio de la carga: <span className="font-family-primary medium big-paragraph">{formatprice.format( rate)} MXN</span></p>
                        <p className="bold">Maniobra de carga: <span className="font-family-primary medium big-paragraph">{originManuever ? formatprice.format( maneuverPrice) : formatprice.format(0)} MXN</span></p>
                        <p className="bold">Maniobra de descarga: <span className="font-family-primary medium big-paragraph">{destinationManuever ? formatprice.format( maneuverPrice) : formatprice.format(0)} MXN</span></p>
                        <hr style={{borderColor:"#0CA669"}}/>
                        <p className="bold">Precio total: <span className="font-family-primary medium big-paragraph">{formatprice.format(totalRate)} MXN</span></p>
                    </Col>
                </Row>
            </Row>
        )
    }
}
export { ShipmentConfirmationDetail }