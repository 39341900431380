import { shipper, admin } from "./config";
/* TODO move to dedicated Files */
/* TODO connect to Redux */

const wsHost = process.env.REACT_APP_API_WS ? process.env.REACT_APP_API_WS : 'ws://localhost:8000';

const registerShipper = async(form) => {
  try {
      const user = await shipper.postRegisterShipper(form);
      return user;
  } catch (error) {
      throw error;
  }
}

const resendConfirmation = async(form) => {
  try {
      const message = await shipper.postResendConfirmation(form);
      return message;
  } catch (error) {
      throw error;
  }
}

const loginShipper = async(form) => {
  try {
      const user = await shipper.postLiftLogin(form);
      return user;
  } catch (error) {
      throw error;
  }
}

const getSepomexByMunicipality = async(municipality) => {
  const filters = { municipality };
  try {
    const sepomexList = await shipper.getSepomex(filters);
    return sepomexList;
  } catch (error) {
    throw error
  }
}

const createNewShipment = async(form) => {
  try {
    const shipment = await shipper.postNewShipment(form);
    return shipment;
  } catch (error) {
    throw error
  }
}

const getShipments = async() => {
  try {
    const shipments = await shipper.getShipments();
    return shipments;
  } catch (error) {
    throw error
  }
}

const getShipmentDetail = async(id) => {
  try {
    const shipment = await shipper.getShipment(id);
    return shipment;
  } catch (error) {
    throw error
  }
}

const pushAllocation = async(id) => {
  try {
    const shipment = await shipper.pushAllocation(id);
    return shipment;
  } catch (error) {
    throw error
  }
}

const getRoutes = async() => {
  try {
    const shipments = await shipper.getRoutes();
    return shipments;
  } catch (error) {
    throw error
  }
}

const getAdminRoutes = async() => {
  try {
    const shipments = await admin.getListRoutes();
    return shipments;
  } catch (error) {
    throw error
  }
}

const suggestNewRoute = async(form) => {
  try {
    const newRoute = await admin.postSuggestRoute(form);
    return newRoute;
  } catch (error) {
    throw error
  }
}

const getZones = async() => {
  try {
    const shipments = await shipper.getZones();
    return shipments;
  } catch (error) {
    throw error
  }
}
const getAdminZones = async() => {
  try {
    const shipments = await admin.getZones();
    return shipments;
  } catch (error) {
    throw error
  }
}

const getRouteInfo = async(id) => {
  try {
    const shipment = await shipper.getRoute(id);
    return shipment;
  } catch (error) {
    throw error
  }
}

export default {
  wsHost,
  registerShipper,
  resendConfirmation,
  loginShipper,
  getSepomexByMunicipality,
  createNewShipment,
  getShipments,
  getShipmentDetail,
  pushAllocation,
  getRoutes,
  getRouteInfo,
  getZones,
  /// ADMIN
  getAdminRoutes,
  getAdminZones,
  suggestNewRoute,
};
