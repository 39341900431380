import React, { Component } from 'react';
import {  Row, Col, Card, Carousel,  } from 'antd';
import './index.less';
import Constants from '../../../helpers/constants';

  const carouselItems=[
      {
        id: 1,
        type:'icon-document2',
        title:'DOCUMENTACIÓN BÁSICA REQUERIDA:',
        paragraph: ' Para poder acceder a cualquier carga con Sendengo nuestros transportistas necesitan completar documentación básica solicitada por nuestro equipo. Estos documentos son validados incluso en portales de gobierno para garantizar su veracidad.',
        link:'¿Quieres saber qué documentos se le solicitan a un transportista? Click Aquí',
        href:'https://sendengo-10-64.ghost.io/documentacion-minima-sendengo/'
      },
      {
        id: 2,
        type:'icon-document',
        title:'Póliza de protección a terceros:',
        paragraph: 'Todos nuestros transportistas cuentan con seguro de protección a terceros para minimizar riesgos. Recuerda que por ley, es responsabilidad del embarcador asegurar la carga.',
        
      },
      {
        id: 3,
        type:'icon-ic_driveravatar',
        title:'Operadores certificados:',
        paragraph: 'Todas las líneas de transporte que trabajan en Rutas Sendengo han certificado a sus operadores (choferes) con Recurso Confiable u Ópalo .',
        link:'¿Quieres saber más sobre estas certificaciones? Click Aquí',
        href:'https://sendengo-10-64.ghost.io/3-mitos-del-rc/'
      },
      {
        id: 4,
        type:'icon--gpsnavigator',
        title:'Monitoreo GPS:',
        paragraph: 'Nuestro equipo monitorea en tiempo real (24/7) y actualiza el estatus de tu carga. Todo transportista que carga con Rutas Sendengo cuenta con GPS .',
        link:'',
        span:'(por seguridad no podemos mostrarte el posicionamiento de la unidad).',
      },
      {
        id: 5,
        type:'icon-Timemoney',
        title:'Devolución de pago:',
        paragraph: 'Si no logramos asignar tu carga, en el tiempo máximo estimado, te devolvemos el dinero sin ningún cargo.',
      },
      {
        id: 6,
        type:'icon-support',
        title:'Soporte personalizado:',
        paragraph: ' Nuestro equipo te asigna asesores de ventas y monitoreo para cada carga que realizas. Siempre puedes solicitar que se comuniquen contigo.',
      },
      {
        id: 7,
        type:'icon-SHIPMENT',
        title:'Carga/Descarga sin costo:',
        paragraph: 'Puedes tardarte hasta 8 horas en carga y 8 horas en descarga sin generar ningún costo accesorial adicional.',
      }
    
  ]
  const listItemsCarousel = carouselItems.map((item) =>
    <Card className="carousel padding-bottom-0" key={item.id}>
        <Row>
            <Col span={5}>
                <p className="icon-carousel"><Constants.IconFont type={item.type} /></p> 
            </Col>
            <Col span={19}>
                <p className="margin-bottom-0 medium font-family-primary uppercase">{item.title}</p>
                <p>{item.paragraph} <a className="font-family-primary color-action" href={item.href} target="_blank" rel="noopener noreferrer">{item.link}</a><span className="small-paragraph">{item.span}</span></p>     
            </Col>
        </Row>
    </Card>
);

class RoutesCarousel extends Component {
    render(){
    
        return( 
            <Carousel effect="fade" autoplay>
                {listItemsCarousel}
            </Carousel>     
        )
    }   
}
export { RoutesCarousel };