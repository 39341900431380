import React from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
} from "antd";
import './index.less';

export default Form.create({
  name: "suggest-form",
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
})(
  class extends React.Component {
    handleSubmit = e => {
      e.preventDefault();
      const { form, postSuggestedRoute } = this.props;
      form.validateFields((err, values) => {
        if (!err) {
          postSuggestedRoute(values)
        }
      });
    };
    render() {
      const { unitsTypes, fields, form } = this.props;
      const { getFieldDecorator } = form;
      const { unitType, origin , destination } = fields;
      return (
        <Form layout="vertical" onSubmit={this.handleSubmit} style={{marginTop: '20px'}}>
          <Form.Item label="Tipo de unidad">
            {getFieldDecorator("unitType", {
              initialValue: unitType.value,
              rules: [
                {
                  required: true,
                  message: "Selecciona una unidad"
                }
              ]
            })(
              <Select name="unitType">
                {unitsTypes.map(unitsType => (
                  <Select.Option key={unitsType.id} value={unitsType.id}>
                    {unitsType.text}
                  </Select.Option>
                ))}
                  <Select.Option key={0} value={0}>
                    Otra
                  </Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Municipio de origen">
            {getFieldDecorator("origin", {
              initialValue: origin.value,
              rules: [
                {
                  required: true,
                  message: "Ingresa Origen"
                }
              ]
            })(
                <Input
                  allowClear={true}
                />
            )}
          </Form.Item>
          <Form.Item label="Municipio de destino">
            {getFieldDecorator("destination", {
              initialValue: destination.value,
              rules: [
                {
                  required: true,
                  message: "Ingresa destino"
                }
              ]
            })(
                <Input
                  allowClear={true}
                />
            )}
          </Form.Item>
          <p className="color-secondary-300">¿Quieres ver tu ruta disponible en el futuro?</p>
          <Form.Item label="Correo de contacto">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: 'email',
                  message: 'No es un correo valido',
                },
                {
                  required: true,
                  message: "Ingresa tu correo"
                }
              ]
            })(
                <Input
                  allowClear={true}
                />
            )}
          </Form.Item>
          <Form.Item className="align-center">
            <Row gutter={8} type="flex" align="center">
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  id="sendSuggestRoute"
                  style={{width:'170px'}}
                >
                  SUGERIR RUTA
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      );
    }
  }
);
